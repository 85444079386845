import businessConfig from '@shared/businessConfig'
import { RechargeSubscription } from '@shared/interfaces/RechargeSubscription.interface'
import { DayNames } from '@shared/helpers/converters/convertDayNameToNumber'

export const getDeliveryDayNameFromSubscription = (
	subscription: RechargeSubscription
): DayNames => {
	return subscription.properties.find(
		attribute => attribute.name === businessConfig.deliveryDayAttributeKey
	)?.value as DayNames
}
