import posthog from 'posthog-js'

// Send custom event to GA and PostHog
const captureCustomEvent = (eventName: string, payload: any) => {
	try {
		// GA disallows capturing personal identifiers
		const payloadWithoutEmail = {
			...payload,
			email: null
		}
		if (window.gtag) {
			window.gtag('event', eventName, payloadWithoutEmail)
		}
		posthog.capture(eventName, payload)
	} catch (error) {
		console.error(error)
	}
}

export default captureCustomEvent