import React, { FC } from 'react'
import classNames from 'classnames'
import ClipboardIcon from '~/assets/icons/clipboard.svg'
import CheckIcon from '~/assets/icons/check.svg'
import { useToast } from '@chakra-ui/toast'
import { useTranslation } from 'next-i18next'
import { InputLabel } from '../MainInput/InputLabel'

interface CopyToClipboardProps {
	label: string;
	value: string;
	className?: string
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({
	value,
	label,
	className
}) => {
	const toast = useToast()
	const { t } = useTranslation()
	const [copied, setCopied] = React.useState(false)
	const handleCopyToClipboard = () => {
		window.navigator.clipboard.writeText(value).then(() => {
			toast({
				title: t('notifications:genericSuccessTitle'),
				description: t('notifications:copyToClipboardSuccessDescription'),
				status: 'success'
			})
			setCopied(true)
		}, () => {
			console.error('Unable to write to clipboard. :-(')
		})
	}

	return (
		<div
			className={className}
		>
			<InputLabel>
				{ label }
			</InputLabel>
			<div
				className={'flex items-center justify-center w-full h-14'}
			>
				<input
					type={'text'}
					readOnly={true}
					value={value}
					className={classNames(
						'w-full bg-neutral-100 rounded-l-md px-3',
						'flex items-center justify-center h-full text-neutral-800',
						'border border-r-0 border-neutral-200 text-sm',
					)}
				>
				</input>
				<button
					className={classNames(
						'inline-flex items-center rounded-r-md p-2 bg-primary-500 h-full',
						'hover:opacity-75 duration-50 active:opacity-50 focus:ring-2 ring-offset-2',
						'ring-primary ring-offset-primary-50 w-14 items-center justify-center'
					)}
					onClick={handleCopyToClipboard}
				>
					{ copied ? (
						<CheckIcon
							width={20}
							stroke={'white'}
							height={20}
						/>
					): (
						<ClipboardIcon
							width={20}
							stroke={'white'}
							height={20}
						/>
					)}
				</button>
			</div>
		</div>
	)
}

export default CopyToClipboard
