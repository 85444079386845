import {
	useCallback,
	useContext
} from 'react'
import apolloStorefrontClient from '@shared/adapters/apolloStorefrontClient'
import { customerAccessTokenCreateWithMultipassQuery } from '~/queries/customerAccessTokenCreateWithMultipass.query'
import { ShopifyError } from '~/interfaces/ShopifyError.interface'
import * as Sentry from '@sentry/nextjs'
import { UserContext } from '~/hocs/UserContextProvider'
import { useMultipass } from '~/hooks/customer/useMultipass'

export const useCustomerAccessToken = () => {
	const {
		customerAccessToken,
		setCustomerAccessToken,
		setCustomerAccessTokenLoading,
		customerAccessTokenLoading
	} = useContext(UserContext)
	const {
		multipassToken,
		fetchMultipassToken
	} = useMultipass()
	const fetchCustomerAccessToken = useCallback(async () => {
		if (customerAccessTokenLoading) {
			console.warn('Customer access token is already being fetched')
			return
		}
		try {
			setCustomerAccessTokenLoading(true)
			const multipassTokenVariable = multipassToken ?? await fetchMultipassToken()
			if (!multipassTokenVariable) {
				console.warn('Multipass token is missing')
				return null
			}
			const response = await apolloStorefrontClient.mutate({
				mutation: customerAccessTokenCreateWithMultipassQuery,
				variables: {
					multipassToken: multipassTokenVariable
				}
			})
			const customerAccessTokenCreateWithMultipass = response.data.customerAccessTokenCreateWithMultipass as {
				customerAccessToken: {
					accessToken: string | null
				} | null;
				customerUserErrors: ShopifyError[];
			}
			const firstCustomerUserError = customerAccessTokenCreateWithMultipass.customerUserErrors[0]
			if (firstCustomerUserError && firstCustomerUserError.field !== 'multipassToken') {
				throw new Error(firstCustomerUserError.message)
			}
			const token = customerAccessTokenCreateWithMultipass.customerAccessToken?.accessToken
			setCustomerAccessToken(token)
			return token
		} catch (error) {
			Sentry.captureException(error, {
				level: 'error',
				extra: {
					description: 'Failed to fetch customer access token'
				},
				tags: {
					source: 'useCustomer/fetchCustomerAccessToken'
				}
			})
			console.error(error)
		} finally {
			setCustomerAccessTokenLoading(false)
		}
	}, [customerAccessTokenLoading, fetchMultipassToken, multipassToken, setCustomerAccessToken, setCustomerAccessTokenLoading])
	const clearCustomerAccessToken = useCallback(() => {
		setCustomerAccessToken(null)
	}, [setCustomerAccessToken])

	return {
		fetchCustomerAccessToken,
		customerAccessTokenLoading,
		customerAccessToken,
		clearCustomerAccessToken
	}
}
