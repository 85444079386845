import businessConfig from '@shared/businessConfig'
import { Moment } from 'moment'
import { getDeliveryDateMomentByChargeDateMoment } from '@shared/helpers/getDeliveryDateMomentByChargeDateMoment'
import { DayNames } from '@shared/helpers/converters/convertDayNameToNumber'

type GetMealSelectionDeliveryDateParams = {
	currentDateMoment: Moment
	region: string
	deliveryDayName?: DayNames
	firstDeliveryDate?: string
	nextChargeDateMoment: Moment
}

export const getMealSelectionDeliveryDate = ({
	deliveryDayName,
	firstDeliveryDate,
	currentDateMoment,
	region,
	nextChargeDateMoment
}: GetMealSelectionDeliveryDateParams) => getDeliveryDateMomentByChargeDateMoment({
	chargeDateMoment: nextChargeDateMoment,
	deliveryDayName,
	deliveries: businessConfig.deliveriesByRegion[region],
	firstDeliveryDate,
	validationMoment: currentDateMoment
})
