import {
	FC,
	useCallback,
	useMemo
} from 'react'
import Dialog from './Dialog/Dialog'
import classNames from 'classnames'
import {
	Alert,
	Button,
	Heading,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs
} from '@chakra-ui/react'
import { LoyaltyChange } from '@shared/interfaces/LoyaltyChange.interface'
import { useRecoilValue } from 'recoil'
import moment from 'moment/moment'
import { Timestamp } from 'firebase/firestore'
import { useAuthState } from '~/hooks/useAuthState'
import businessConfig from '@shared/businessConfig'
import HomeIcon from '~/assets/icons/home.svg'
import ClockIcon from '~/assets/icons/clock.svg'
import TagIcon from '~/assets/icons/tag.svg'
import UsersIcon from '~/assets/icons/users.svg'
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard'
import { useTranslation } from 'next-i18next'
import { loyaltyActionsCouponsState } from '~/store/atoms/customer/loyaltyActionsCouponsState'
import { loyaltyPointsState } from '~/store/atoms/customer/loyaltyPointsState'
import {
	ILoyaltyAction,
	IReferralFields
} from '@shared/types/generated/contentful'
import { useTimezone } from '~/hooks/useTimezone'
import { loyaltyActionsState } from '~/store/atoms/customer/loyaltyActionsState'
import { useHandleRedeemCouponCode } from '~/hooks/useHandleRedeemCouponCode'
import { useRouter } from 'next/router'
import { useSubscriptions } from '~/hooks/useSubscriptions'
import { globalSettingsState } from '~/store/global'

type LoyaltyDialogProps = {
	loyaltyChanges: LoyaltyChange[]
	open: boolean
	onClose: (updateQueryParam?: boolean) => void
	referral: IReferralFields
	defaultIndex?: number | null
}

export const LoyaltyDialog: FC<LoyaltyDialogProps> = ({
	loyaltyChanges,
	open,
	onClose,
	referral,
	defaultIndex
}) => {
	const globalSettings = useRecoilValue(globalSettingsState)
	const loyaltySpendLimit = useMemo(() => {
		return globalSettings?.loyaltySpendLimit ?? 0
	}, [globalSettings])
	const allSpentLoyaltyPoints = useMemo(() => {
		return loyaltyChanges.reduce(
			(acc, loyaltyChange) => acc + (
				loyaltyChange.type === 'coupon-creation' ? -loyaltyChange.points : 0
			),
			0
		)
	}, [loyaltyChanges])
	const percentOfSpendLimit = useMemo(() => {
		if (!loyaltySpendLimit) return 0
		return Math.round(
			(allSpentLoyaltyPoints / loyaltySpendLimit) * 100
		)
	}, [allSpentLoyaltyPoints, loyaltySpendLimit])
	const router = useRouter()
	const { activeDeliveriesSubscriptionsWithData } = useSubscriptions()
	const newSubscriptionId = 0
	const [user] = useAuthState()
	const {
		handleRedeemCouponCode,
		loadingSubscriptionId
	} = useHandleRedeemCouponCode()
	const { timezone } = useTimezone()
	const loyaltyPoints = useRecoilValue(loyaltyPointsState)
	const loyaltyActions = useRecoilValue(loyaltyActionsState)
	const loyaltyActionsCoupons = useRecoilValue(loyaltyActionsCouponsState)
	const referralLink = useMemo(() => {
		if (typeof window === 'undefined') return null
		if (!user) return null
		const localePath = router.locale === router.defaultLocale ? '' : `/${router.locale}`
		return window.location.origin + localePath + referral.redirectUrl + '?referral=' + user.uid
	 }, [referral.redirectUrl, router.defaultLocale, router.locale, user])
	const { t } = useTranslation()
	const loyaltyActionById = useCallback((id: string) => {
		return loyaltyActions.find(action => action.sys.id === id)
	}, [
		loyaltyActions
	])
	const handleRedeemCouponCodeClick = useCallback((action: ILoyaltyAction) => {
		return async () => {
			if (activeDeliveriesSubscriptionsWithData === 'loading') {
				return
			}
			if (activeDeliveriesSubscriptionsWithData.length === 0) {
				await handleRedeemCouponCode(
					action.sys.id,
					newSubscriptionId,
					async (couponCode) => {
						await router.push({
							pathname: '/checkout/plans',
							query: {
								discount: couponCode
							}
						})
						onClose(false)
					}
				)
			} else {
				await router.push({
					pathname: '/customer/redeem',
					query: {
						loyaltyActionCouponId: action.sys.id
					}
				})
				onClose(false)
			}
		}
	}, [activeDeliveriesSubscriptionsWithData, handleRedeemCouponCode, onClose, router])
	const tabs = [
		{
			value: 'home',
			name: t('loyalty:homeTitle'),
			icon: (
				<HomeIcon
					width={17}
					stroke={'#222'}
				/>
			),
			content: (
				<>
					<Heading
						as={'h2'}
						size={'xl'}
						className={'font-semibold'}
					>
						{ t('loyalty:homeTitle') }
					</Heading>
					<p
						className={'text-neutral-500 mt-2'}
					>
						{ t('loyalty:homeDescription') }
					</p>
					<div
						className={'w-full h-px bg-neutral-100 my-4'}
					/>
					<div
						className={'mt-4 w-full'}
					>
						<Heading
							as={'h3'}
							size={'xl'}
							className={'text-xl font-semibold'}
						>
							{t('loyalty:points', {
								points: loyaltyPoints
							})}
						</Heading>
						<p
							className={'text-neutral-500'}
						>
							{ t('loyalty:totalCollectedPointsLabel') }
						</p>
					</div>
				</>
			)
		},
		{
			value: 'history',
			name: t('loyalty:historyTitle'),
			icon: (
				<ClockIcon
					width={17}
					stroke={'#222'}
				/>
			),
			content: (
				<div
					className={'flex flex-col items-start'}
				>
					<Heading
						as={'h2'}
						size={'xl'}
						className={'font-semibold'}
					>
						{ t('loyalty:historyTitle') }
					</Heading>
					<div
						className={'w-full h-px bg-neutral-100 my-4'}
					/>
					{ loyaltyChanges.length === 0 && (
						<div
							className={classNames(
								'text-neutral-500 rounded bg-neutral-100 px-4 py-8 w-full text-center'
							)}
						>
							{ t('loyalty:historyIsEmpty') }
						</div>
					)}
					{
						loyaltyChanges.map((loyaltyChange, index) => (
							<div
								className={'w-full mb-3'}
								key={'loyalty-changes' + index}
							>
								<div
									className={'flex items-center justify-between w-full'}
								>
									<div>
										<p
											className={'text-lg font-semibold'}
										>
											{ loyaltyActionById(loyaltyChange.loyaltyActionId)?.fields.title || loyaltyChange.title }
										</p>
										<p
											className={'text-sm text-neutral-500'}
										>
											{ loyaltyActionById(loyaltyChange.loyaltyActionId)?.fields.description || loyaltyChange.description }
										</p>
									</div>
									<div
										className={'text-right'}
									>
										<p
											className={'font-semibold mt-2'}
										>
											{t('loyalty:points', {
												points: loyaltyChange.points
											})}
										</p>
										{
											loyaltyChange.createdAt instanceof Timestamp && (
												<p
													className={'text-sm text-neutral-500'}
												>
													{ moment.utc(loyaltyChange.createdAt.seconds * 1000).tz(timezone).format('DD/MM/YY') }
												</p>
											)
										}
									</div>
								</div>
								{
									loyaltyChange.couponCode && (
										<Alert
											className={'mt-2'}
										>
											<span>{ t('loyalty:couponCodeLabel') }</span>
											<span
												className={'ml-1 font-semibold'}
											>
												{ loyaltyChange.couponCode }
											</span>
										</Alert>
									)
								}
							</div>
						))
					}
				</div>
			)
		},
		{
			value: 'coupons',
			name: t('loyalty:couponsTitle'),
			icon: (
				<TagIcon
					width={17}
					stroke={'#222'}
				/>
			),
			content: (
				<div
					className={'flex flex-col items-start'}
				>
					<Heading
						as={'h2'}
						size={'xl'}
						className={'font-semibold'}
					>
						{ t('loyalty:couponsTitle') }
					</Heading>
					<p>
						{ t('loyalty:couponsSpendLimit') }
					</p>
					<div
						className={'w-full flex items-center'}
					>
						<div
							className={'w-3/4 bg-gray-200 rounded-full h-1.5'}
						>
							<div
								className={'bg-keppel h-1.5 rounded-full'}
								style={{
									width: `${percentOfSpendLimit > 100 ? 100 : percentOfSpendLimit}%`
								}}
							/>
						</div>
						<span
							className={'text-sm text-neutral-500 ml-2'}
						>
							{ allSpentLoyaltyPoints }{'/'}{ loyaltySpendLimit }
						</span>
					</div>
					<div
						className={'w-full h-px bg-neutral-100 my-4'}
					/>
					{
						loyaltyActionsCoupons.map((action, index) => (
							<div
								className={'my-2 w-full flex items-center justify-between'}
								key={'loyalty-actions-coupons' + index}
							>
								<div>
									<p
										className={'text-lg font-semibold'}
									>
										{ action.fields.title }
									</p>
									<p
										className={'text-sm text-neutral-500'}
									>
										{ t('loyalty:valueLabel') } { action.fields.couponValue }{
											action.fields.couponType === 'fixed' ? '€' : '%'
										}
									</p>
								</div>
								<Button
									className={'mt-2'}
									variant={'solid'}
									colorScheme={'keppel'}
									isDisabled={
										action.fields.points > loyaltyPoints ||
										allSpentLoyaltyPoints >= loyaltySpendLimit
									}
									onClick={handleRedeemCouponCodeClick(action)}
									isLoading={loadingSubscriptionId === newSubscriptionId}
								>
									{ t('loyalty:redeemButtonText') }{' ('}{t('loyalty:points', {
										points: action.fields.points
									})}{')'}
								</Button>
							</div>
						))
					}
				</div>
			)
		},
		{
			value: 'referrals',
			name: t('loyalty:referralsTitle'),
			icon: (
				<UsersIcon
					width={17}
					stroke={'#222'}
				/>
			),
			content: (
				<>
					<Heading
						as={'h2'}
						size={'xl'}
						className={'font-semibold'}
					>
						{ t('loyalty:referralsTitle') }
					</Heading>
					<p
						className={'text-neutral-500 mt-2'}
					>
						{ t('loyalty:referralsDescription') }
					</p>
					<div
						className={'w-full h-px bg-neutral-100 my-4'}
					/>
					<CopyToClipboard
						value={referralLink}
						label={t('loyalty:referralLinkLabel')}
					/>
				</>
			)
		}
	]

	return (
		<Dialog
			open={open}
			padding={false}
			xButtonColor={'white'}
			onClose={onClose}
		>
			<div
				className={'bg-teal-500 w-full p-6 pr-5 bg-pattern-1 bg-cover'}
			>
				<p
					className={'font-semibold text-white'}
				>
					{ t('loyalty:title') }
				</p>
				<div
					className={'mt-2 w-full flex items-center justify-between'}
				>
					<Heading
						as={'h1'}
						size={'3xl'}
						className={'font-semibold text-white'}
					>
						{t('loyalty:points', {
							points: loyaltyPoints
						})}
					</Heading>
					<div
						className={classNames(
							'flex items-center px-2 py-1 rounded',
							'bg-neutral-100 text-teal-600'
						)}
					>
						<p
							className={'text-sm'}
						>
							{ businessConfig.currencyCode }
						</p>
					</div>
				</div>
			</div>
			<Tabs
				className={'flex flex-col-reverse sm:flex-col w-full'}
				defaultIndex={defaultIndex}
			>
				<TabList
					className={'bg-neutral-100 flex flex-row w-full pt-2 px-6 space-x-5 overflow-x-scroll overflow-y-hidden'}
				>
					{tabs.map((tab) => (
						<Tab
							key={tab.value}
							className={classNames(
								'flex items-center justify-center',
								'pt-1 pb-2 px-1 text-sm text-center whitespace-nowrap cursor-base focus:outline-none',
								'flex-shrink-0 inline-block opacity-50 text-neutral-900 border-b-2',
								'hover:text-neutral-900 duration-100',
								'selected:opacity-100 active:opacity-75 selected:border-teal-500'
							)}
						>
							{tab.icon}
							<p
								className={'ml-1'}
							>
								{tab.name}
							</p>
						</Tab>
					))}
				</TabList>
				<TabPanels>
					{tabs.map((tab) => (
						<TabPanel
							key={tab.value}
							className={'flex-grow-1 h-full sm:h-[500px] overflow-y-scroll p-6'}
						>
							{ tab.content }
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>
		</Dialog>
	)
}
