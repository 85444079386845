import { recoilPersist } from 'recoil-persist'

const localStorage = typeof window === 'undefined' ? null : window.localStorage
const sessionStorage = typeof window === 'undefined' ? null : window.sessionStorage
const { persistAtom: persistAtomLocal } = recoilPersist({
	key: 'recoil-persist',
	storage: localStorage
})
const { persistAtom: persistAtomSession } = recoilPersist({
	key: 'recoil-persist',
	storage: sessionStorage
})

export const persistAtom = (
	storage: 'session' | 'local'
) => (
	storage === 'session' ?
		persistAtomSession : persistAtomLocal
)
