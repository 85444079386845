import {
	setCookie,
	deleteCookie
} from 'cookies-next'
import { UserRecord } from 'firebase-admin/auth'
import posthog from 'posthog-js'
import {
	FC,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react'
import * as Sentry from '@sentry/nextjs'
import {
	collection,
	query,
	where,
	orderBy,
	onSnapshot
} from 'firebase/firestore'
import {
	db,
	auth
} from '~/adapters/firebase'
import { LoyaltyChange } from '~/functions/src/shared/interfaces/LoyaltyChange.interface'
import { useRecoilState } from 'recoil'
import { useCustomer } from '~/hooks/useCustomer'
import { useFirebaseProfile } from '~/hooks/useFirebaseProfile'
import { loyaltyChangesState } from '~/store/atoms/customer/loyaltyChangesState'
import { userLoadingState } from '~/store/atoms/firebase/userLoadingState'
import { userRoleState } from '~/store/atoms/dashboard/userRoleState'
import {
	dashboardRoles,
	DashboardRoles
} from '~/types/roles'
import { numberOfOrdersByDateState } from '~/store/atoms/dashboard/numberOfOrdersByDateState'
import { customerUpdate } from '~/adapters/shopify/customerUpdate'
import { customerUpdateQueueState } from '~/store/atoms/customer/customerUpdateQueue'
import Link from 'next/link'
import { Button } from '@chakra-ui/react'
import { UserContext } from '~/hocs/UserContextProvider'
import { useCustomerDeliverySchedule } from '~/hooks/customer/useCustomerDeliverySchedule'
import { useRechargeApi } from '~/hooks/useRechargeApi'
import { getRedirectResult } from 'firebase/auth'

type AuthenticationProviderProps = {
	props: Record<any, any>
}

export const AuthenticationProvider: FC<AuthenticationProviderProps> = ({ children }) => {
	const {
		fetchRechargeSession,
		clearRechargeSession,
		rechargeSession,
		fetchRechargeCustomer,
		fetchRechargeOrders
	} = useRechargeApi()
	const { fetchCustomerDeliverySchedule } = useCustomerDeliverySchedule()
	const { setUser } = useContext(UserContext)
	const [customerUpdateQueue, setCustomerUpdateQueue] = useRecoilState(customerUpdateQueueState)
	const [userRole, setUserRole] = useRecoilState(userRoleState)
	const {
		fetchCustomerData,
		clearCustomer,
		customerAccessToken
	} = useCustomer()
	const {
		clearFirebaseProfile,
		subscribe
	} = useFirebaseProfile()
	const [, setLoyaltyChanges] = useRecoilState(loyaltyChangesState)
	const [, setUserLoading] = useRecoilState(userLoadingState)
	const subscribeLoyaltyChanges = useCallback((
		email: string
	) => {
		const loyaltyChangesRef = collection(db, 'loyaltyChanges')
		const q = query(
			loyaltyChangesRef,
			where('customerEmail', '==', email),
			orderBy('createdAt', 'desc')
		)

		return onSnapshot(q, snapshot => {
			setLoyaltyChanges(
			snapshot.docs.map(doc => doc.data()) as LoyaltyChange[]
			)
		})
	}, [setLoyaltyChanges])
	const [
		numberOfOrdersByDate, setNumberOfOrdersByDate
	] = useRecoilState(numberOfOrdersByDateState)
	const [isNumberOfOrdersByDateFetched, setIsNumberOfOrdersByDateFetched] = useState(false)

	useEffect(() => {
		if (!customerAccessToken) return
		if (rechargeSession) return
		fetchRechargeSession().then(session => {
			fetchRechargeCustomer(session)
			fetchRechargeOrders(session)
			fetchCustomerDeliverySchedule(session)
		})
	}, [customerAccessToken, fetchCustomerDeliverySchedule, fetchRechargeCustomer, fetchRechargeOrders, fetchRechargeSession, rechargeSession])

	useEffect(() => {
		if (!customerUpdateQueue || !customerAccessToken) return
		customerUpdate({
			customer: customerUpdateQueue,
			customerAccessToken
		}).catch(error => {
			Sentry.captureException(error, {
				level: 'error',
				extra: {
					description: 'Failed to update customer'
				},
				tags: {
					source: 'customerUpdate'
				}
			})
		})
		setCustomerUpdateQueue(null)
	}, [
		customerUpdateQueue,
		customerAccessToken,
		setCustomerUpdateQueue
	])

	useEffect(() => {
		let unsubscribe: (() => void) | null
		getRedirectResult(auth).then(() => {
			unsubscribe = auth.onIdTokenChanged(async (currentUser) => {
				try {
					setUserLoading(true)
					if (!currentUser) {
						await getRedirectResult(auth)
					}
					if (currentUser) {
						currentUser.getIdTokenResult().then(({ claims }) => {
							setUserRole(claims.role as DashboardRoles)
						})

						const token = await currentUser.getIdToken()
						fetchCustomerData()
						setUser(currentUser.toJSON() as UserRecord)
						subscribe(currentUser.email)
						subscribeLoyaltyChanges(currentUser.email)
						setCookie('authToken', token)
						Sentry.setUser({
							email: currentUser.email
						})
					} else {
						clearRechargeSession()
						setUser(null)
						setUserRole(null)
						deleteCookie('authToken')
						clearFirebaseProfile()
						clearCustomer()
						Sentry.setUser(null)
						posthog.reset()
					}
				} finally {
					setUserLoading(false)
				}
			})
		})
		return () => unsubscribe?.()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>

			{children}
			{
				dashboardRoles.includes(userRole) && (
					<Link
						id={'dashboard-link'}
						className={'fixed bottom-0 z-40 rotate-90'}
						href={'/dashboard'}
						style={{
							marginBottom: '150px',
							left: '-48px'
						}}
					>
						<Button
							size={'lg'}
							variant={'solid'}
							colorScheme={'orange'}
							borderBottomRadius={'0'}
							// eslint-disable-next-line react/jsx-no-literals
						>
							{/* eslint-disable-next-line react/jsx-no-literals */}
							{'Dashboard'}
						</Button>
					</Link>
				)
			}
		</>
	)
}
