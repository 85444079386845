import { rechargeSessionState } from '~/store/atoms/recharge/rechargeSessionState'
import { useRecoilState } from 'recoil'
import {
	useCallback,
	useState
} from 'react'
import {
	Address,
	Session,
	Subscription,
	getCustomer,
	listOrders,
	loginShopifyApi
} from '@rechargeapps/storefront-client'
import { useCustomer } from '~/hooks/useCustomer'
import { rechargeCustomerState } from '~/store/atoms/recharge/rechargeCustomerState'
import { userSubscriptionsWithAddressesState } from '~/store/atoms/recharge/userSubscriptionsWithAddressesState'
import { userPaymentMethodsState } from '~/store/atoms/recharge/userPaymentMethodsState'
import { userRechargeOrdersState } from '~/store/atoms/recharge/userRechargeOrdersState'
import axios, { AxiosResponse } from 'axios'
import { ReactivateSubscriptionBody } from '~/interfaces/ReactivateSubscriptionBody.interface'
import businessConfig from '@shared/businessConfig'

export const useRechargeApi = () => {
	const { customerAccessToken } = useCustomer()
	const [rechargeSession, setRechargeSession] = useRecoilState(rechargeSessionState)
	const [rechargeCustomer, setRechargeCustomer] = useRecoilState(rechargeCustomerState)
	const [rechargeCustomerLoading, setRechargeCustomerLoading] = useState(false)
	const [, setSubscriptionsWithAddresses] = useRecoilState(userSubscriptionsWithAddressesState)
	const [, setPaymentMethods] = useRecoilState(userPaymentMethodsState)
	const [, setOrders] = useRecoilState(userRechargeOrdersState)
	const fetchRechargeCustomer = useCallback(async (session?: Session) => {
		setRechargeCustomerLoading(true)
		if (!rechargeSession && !session) return
		if (session?.customerId == null) {
			console.error('fetchRechargeCustomer', 'customerId is null')
			setRechargeCustomerLoading(false)
			return
		}
		try {
			const customer = await getCustomer(session ?? rechargeSession, {
				include: ['addresses', 'subscriptions', 'payment_methods']
			})
			setRechargeCustomer(customer)
			if (customer.include.subscriptions) {
				const subscriptionsWithAddresses = customer.include.subscriptions.map(subscription => {
					const address = customer.include.addresses.find(e => e.id === subscription.address_id)
					return {
						subscription,
						address
					}
				}).filter(({ subscription }) => {
					const productId = subscription.external_product_id.ecommerce
					return !businessConfig.shippingFeeProductsIds.includes(productId)
				})
				setSubscriptionsWithAddresses(subscriptionsWithAddresses)
			}
			if (customer.include.payment_methods) {
				setPaymentMethods(customer.include.payment_methods)
			}
		} catch (error) {
			console.error(error)
			setRechargeCustomer(null)
		} finally {
			setRechargeCustomerLoading(false)
		}
	}, [rechargeSession, setPaymentMethods, setRechargeCustomer, setSubscriptionsWithAddresses])
	const fetchRechargeOrders = useCallback(async (session?: Session) => {
		if (!rechargeSession && !session) return
		if (session?.customerId == null) {
			console.error('fetchRechargeOrders', 'customerId is null')
			return
		}
		try {
			const { orders } = await listOrders(session ?? rechargeSession)
			setOrders(orders)
		} catch (error) {
			setOrders([])
		}
	}, [rechargeSession, setOrders])
	const fetchRechargeSession = useCallback(async () => {
		if (!customerAccessToken) return
		try {
			const session = await loginShopifyApi(businessConfig.storefrontAccessToken, customerAccessToken)
			setRechargeSession(session)
			return session
		} catch (error) {
			console.error('fetchRechargeSession', error)
			setRechargeSession(null)
		}
	}, [customerAccessToken, setRechargeSession])
	const clearRechargeSession = useCallback(() => {
		setRechargeSession(null)
	}, [setRechargeSession])
	const clearRechargeCustomer = useCallback(() => {
		setRechargeCustomer(null)
	}, [setRechargeCustomer])
	const reactivateSubscription = async (body: ReactivateSubscriptionBody) => {
		const response = await axios.post<ReactivateSubscriptionBody, AxiosResponse<{
			subscription: Subscription
			address: Address
		}>>(
			'/api/reactivate-subscription',
			body
		)

		return response.data
	}

	return {
		fetchRechargeSession,
		rechargeSession,
		setRechargeSession,
		clearRechargeSession,
		fetchRechargeCustomer,
		rechargeCustomer,
		rechargeCustomerLoading,
		clearRechargeCustomer,
		fetchRechargeOrders,
		reactivateSubscription
	}
}
