import {
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import classNames from 'classnames'
import XIcon from 'assets/icons/x.svg'
import {
	getCookie,
	setCookie
} from 'cookies-next'
import AlertIcon from 'assets/icons/alert-circle.svg'

type NoticeBannerProps = {
	id: string
	color: 'amber' | 'red' | 'green' | 'blue'
	text: string
}

export const NoticeBanner: FC<NoticeBannerProps> = ({
	text,
	color,
	id
}) => {
	const [bannerHidden, setBannerHidden] = useState(true)
	const handleBannerClose = useCallback(() => {
		setBannerHidden(true)
		setCookie('noticeBannerHidden-' + id, 'true')
	}, [id])

	useEffect(() => {
		const noticeBannerCookie = getCookie('noticeBannerHidden-' + id)
		setBannerHidden(noticeBannerCookie?.toString() === 'true')
	}, [id])

	const bgClasses = useMemo(() => {
		switch (color) {
			case 'amber':
				return 'bg-amber-100 border-amber-200'
			case 'red':
				return 'bg-red-100 border-red-200'
			case 'green':
				return 'bg-green-100 border-green-200'
			case 'blue':
				return 'bg-blue-100 border-blue-200'
		}
	}, [color])
	const textColor = useMemo(() => {
		switch (color) {
			case 'amber':
				return 'text-amber-700'
			case 'red':
				return 'text-red-700'
			case 'green':
				return 'text-green-700'
			case 'blue':
				return 'text-blue-700'
		}
	}, [color])

	return (
		<div
			className={classNames(
				'w-full sm:h-14 flex-col sm:flex-row items-center sm:justify-center',
				'font-semibold relative text-center px-1 py-2 border-t border-b',
				bannerHidden ? 'hidden' : 'flex',
				bgClasses
			)}
		>
			<AlertIcon
				className={`${textColor} mr-4`}
				width={20}
				height={20}
			/>
			<p
				className={textColor}
				dangerouslySetInnerHTML={{
					__html: text
				}}
			/>
			<button
				className={'flex mr-4 cursor-pointer absolute right-0 bottom-4 sm:bottom-auto items-center p-1'}
				onClick={handleBannerClose}
			>
				<XIcon
					width={17}
					height={17}
					className={'hover:opacity-75 duration-100'}
				/>
			</button>
		</div>
	)
}
