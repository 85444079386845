import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import NextNProgress from 'nextjs-progressbar'
import {
	getLanguageFromLocale,
	getRegionFromLocale
} from '~/helpers/locale'
import {
	useEffect,
	useMemo,
	useRef
} from 'react'
import Script from 'next/script'
import 'moment/locale/fr'
import 'moment/locale/es'
import 'moment/locale/pt'
import {
	appWithTranslation,
	i18n
} from 'next-i18next'
import nextI18NextConfig from '../i18nextConfig'
import posthog from 'posthog-js'
import { useLocalStorage } from 'usehooks-ts'
import { ChakraProvider } from '@chakra-ui/react'
import { chakraTheme } from '~/chakraTheme'
import { AppPageProps } from '~/interfaces/AppPageProps.interface'
import * as Sentry from '@sentry/nextjs'
import { useTimezone } from '~/hooks/useTimezone'
import '@fontsource/source-serif-pro/900.css'
import StaticDataProvider from '~/hocs/StaticDataProvider'
import { GlobalStaticProps } from '~/interfaces/GlobalStaticProps.interface'
import { Toaster } from 'sonner'
import businessConfig from '~/functions/src/shared/businessConfig'
import { initRecharge } from '@rechargeapps/storefront-client'

declare global {
	interface Window {
		ga: any
		analytics: any
		uetq: any
		fbq: any
		gtag: any
		gaplugins: any
		Cookiebot: any
		GorgiasChat: any
	}
}

initRecharge({
	storeIdentifier: businessConfig.rechargeStoreIdentifier,
	storefrontAccessToken: businessConfig.rechargeStorefrontAccessToken
})

function MyApp({
	Component,
	pageProps
}: AppProps<AppPageProps>) {
	useTimezone()
	const isFirstLoad = useRef(true)
	const router = useRouter()
	const language = useMemo(
		() => getLanguageFromLocale(router.locale),
		[router.locale]
	)
	const region = useMemo(
		() => getRegionFromLocale(router.locale),
		[router.locale]
	)

	useEffect(() => {
		if (typeof window === 'undefined') return
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			pageLanguage: language.toLowerCase(),
			pageLanguageUppercase: language.toUpperCase(),
			pageRegion: region.toLowerCase(),
			pageRegionUppercase: region.toUpperCase()
		})
	}, [language, region])

	const [
		_appliedReferralCode, setAppliedReferralCode
	] = useLocalStorage('appliedReferralCode', null)

	router.events?.on('hashChangeComplete', (path: string) => {
		if (
			path.includes('open-cookie-preferences') &&
			window.Cookiebot
		) {
			window.Cookiebot.show()
		}
		if (
			path.includes('contact') &&
			window.GorgiasChat
		) {
			window.GorgiasChat.open()
		}
	})

	useEffect(() => {
		if (!isFirstLoad.current) return
		isFirstLoad.current = false
		setTimeout(() => {
			if (
				router.asPath.includes('contact') &&
				window.GorgiasChat
			) {
				window.GorgiasChat.open()
			}
		}, 4000)
	}, [router.asPath])

	useEffect(() => {
		try {
			posthog.capture('$pageview')
		} catch (error) {
			console.error(error)
		}
		if (window.analytics) {
			window.analytics.page(router.asPath)
		}
	}, [
		router.asPath
	])

	useEffect(() => {
		if (!router.query.referral) return
		setAppliedReferralCode(router.query.referral)
	}, [
		router.query,
		setAppliedReferralCode
	])

	i18n.on('missingKey', (lngs, namespace, key, res) => {
		Sentry.captureException(new Error('Empty translation key'), {
			level: 'error',
			extra: {
				lngs,
				namespace,
				key,
				res
			},
			tags: {
				source: 'i18next',
				missingKey: `${namespace}:${key}`
			}
		})
	})

	if (pageProps.notFoundPathname === router.pathname) {
		return (
			<ChakraProvider
				theme={chakraTheme}
			>
				<Component />
			</ChakraProvider>
		)
	}

	return (
		<>
			<link
				rel={'preconnect'}
				href={'https://dev.visualwebsiteoptimizer.com'}
			/>
			<Script
				src={'/scripts/vwo.js'}
			/>
			<Script
				defer={true}
				strategy={'afterInteractive'}
				src={'/scripts/marker-io.js'}
			/>
			<ChakraProvider
				theme={chakraTheme}
			>
				<div
					className={'main-background-color min-h-screen'}
				>
					<NextNProgress
						color={'#f97316'}
						startPosition={0.3}
						stopDelayMs={200}
						height={4}
						showOnShallow={false}
						nonce={'router-loading'}
					/>
					<Toaster
						toastOptions={{
							classNames: {
								toast: 'rounded-3xl'
							}
						}}
						position={'bottom-center'}
					/>
					<StaticDataProvider
						props={pageProps as unknown as GlobalStaticProps}
					>
						<Component
							{...pageProps}
						/>
					</StaticDataProvider>
				</div>
			</ChakraProvider>
		</>
	)
}

export default appWithTranslation(MyApp, nextI18NextConfig)
