import { FC } from 'react'
import classNames from 'classnames'
import ArrowLeftIcon from '~/assets/icons/arrow-left.svg'

export interface PageHeaderProps {
	title: string;
	onBack?: () => void;
	subTitle?: string;
	className?: string;
}

export const PageHeader: FC<PageHeaderProps> = ({
	title,
	onBack,
	subTitle,
	className
}): JSX.Element => {
	return (
		<div
			className={classNames(
				className,
				'w-full flex items-center justify-start py-4',
			)}
		>
			{ onBack && (
				<button
					onClick={onBack}
					className={classNames(
						'flex items-center justify-center w-10 h-10 rounded-full transition-colors duration-200',
						'hover:bg-neutral-200 dark:hover:bg-blackAlpha-50 focus:opacity-70'
					)}
				>
					<ArrowLeftIcon
						width={24}
						height={24}
					/>
				</button>
			)}
			<span
				className={'font-semibold text-2xl ml-2'}
			>
				{ title }
			</span>
			{ subTitle && (
				<span
					className={'text-sm text-neutral-500 ml-4 mt-px'}
				>
					{ subTitle }
				</span>
			)}
		</div>
	)
}
