import businessConfig, { BusinessConfigDelivery } from '@shared/businessConfig'
import { DeliverySkip } from '@shared/interfaces/DeliverySkip.interface'
import { RechargeCharge } from '@shared/interfaces/RechargeCharge.interface'
import { RechargeSubscription } from '@shared/interfaces/RechargeSubscription.interface'
import { Moment } from 'moment-timezone'
import { DayNames } from '../converters/convertDayNameToNumber'
import { getDeliveryDayNameFromSubscription } from '../extractors/getDeliveryDayNameFromSubscription'
import { getFirstDeliveryDateFromSubscription } from '../extractors/getFirstDeliveryDateFromSubscription'
import { getCorrectDeliveryDayName } from '../getCorrectDeliveryDayName'
import { getDeliveryDateMomentByChargeDateMoment } from '../getDeliveryDateMomentByChargeDateMoment'
import momentUtc from '../momentUtc'
import { sortRechargeOrders } from '../sortRechargeOrders'
import { getMealSelectionByMoment } from './getMealSelectionByMoment'
import { getMealSelectionsByDeliverySchedule } from './getMealSelectionsByDeliverySchedule'
import { getMealSelectionsByNextCharges } from './getMealSelectionsByNextCharges'
import {
	getLastDeliveryMomentFromRechargeOrder
} from '@shared/helpers/extractors/getLastDeliveryMomentFromRechargeOrder'
import { getOneTimeDeliveryDateFromSubscription } from '../extractors/getOneTimeDeliveryDateFromSubscription'
import { sortRechargeCharges } from '../sortRechargeCharges'
import { Delivery, Order } from '@rechargeapps/storefront-client'

export type MealSelection = {
	deliveryMoment: Moment
	isMealsSelectionTooLate: boolean
	maxMealSelectionMoment: Moment
	paymentStatus: 'paid' | 'unpaid'
	chargeOrOrder?: RechargeCharge | Order
}

export type GetDeliveryInfoParams = {
	currentDateMoment: Moment
	region: string
	subscription: RechargeSubscription
	orders: Order[]
	nextCharges: RechargeCharge[]
	deliverySchedule?: Delivery[]
	deliverySkips?: DeliverySkip[]
}

export type DeliveryInfo = {
	firstDeliveryMoment: Moment | null
	lastDeliveryMoment: Moment
	upcomingDeliveryMoment?: Moment
	deliveryDayName: DayNames | null
	mealSelections: MealSelection[]
	upcomingMealSelection?: MealSelection
}

export type GetDeliveryInfo = (
	params: GetDeliveryInfoParams
) => DeliveryInfo

const getLastOrderMealSelection = (
	deliveries: BusinessConfigDelivery[],
	currentMoment: Moment,
	order: Order,
	deliveryMoment?: Moment
) => {
	if (!deliveryMoment) return null
	return getMealSelectionByMoment(
		deliveryMoment,
		deliveries,
		currentMoment,
		'paid',
		order
	)
}

const getFirstDeliveryMealSelection = (
	deliveries: BusinessConfigDelivery[],
	currentMoment: Moment,
	firstDeliveryDate?: string,
	order?: Order
) => {
	if (firstDeliveryDate) {
		const firstDeliveryMoment = momentUtc(firstDeliveryDate, businessConfig.firstDeliveryDateFormat)
		return getMealSelectionByMoment(
			firstDeliveryMoment,
			deliveries,
			currentMoment,
			'paid',
			order
		)
	}
	return null
}
const getOneTimeDeliveryMealSelection = (
	deliveries: BusinessConfigDelivery[],
	currentMoment: Moment,
	oneTimeDeliveryDate?: string,
	charge?: RechargeCharge
) => {
	if (oneTimeDeliveryDate) {
		const oneTimeDeliveryMoment = momentUtc(oneTimeDeliveryDate, businessConfig.firstDeliveryDateFormat)
		return getMealSelectionByMoment(
			oneTimeDeliveryMoment,
			deliveries,
			currentMoment,
			'paid',
			charge
		)
	}
	return null
}

const getLastDeliveryMoment = (
	order: Order,
	chargeMoment: Moment,
	firstDeliveryDate: string | undefined,
	deliveries: BusinessConfigDelivery[],
	subscription: RechargeSubscription
) => {
	let lastDeliveryMoment = getLastDeliveryMomentFromRechargeOrder(order)
	if (!lastDeliveryMoment) {
		const deliveryDayName = getDeliveryDayNameFromSubscription(subscription)
		lastDeliveryMoment = getDeliveryDateMomentByChargeDateMoment({
			chargeDateMoment: chargeMoment,
			deliveries,
			firstDeliveryDate,
			validationMoment: momentUtc(order.created_at),
			deliveryDayName
		})
	}
	return lastDeliveryMoment
}

const getDeliveryInfo: GetDeliveryInfo = ({
	currentDateMoment,
	region,
	subscription,
	orders,
	nextCharges,
	deliverySchedule,
	deliverySkips
}) => {
	const deliveries = businessConfig.deliveriesByRegion[region]
	const sortedCharges = nextCharges.filter(
		charge => charge.line_items.find(e => e.purchase_item_id === subscription.id) &&
		charge.status === 'success'
	).sort(sortRechargeCharges)
	const sortedOrders = orders.filter(
		order => order.line_items.find(e => e.purchase_item_id === subscription.id) &&
			order.status === 'success'
	).sort(sortRechargeOrders)
	const upcomingCharge = sortedCharges.filter(charge => charge.status === 'queued')[0]
	const lastOrder = sortedOrders[0]
	const firstOrder = sortedOrders[sortedOrders.length - 1]
	const lastChargeMoment = lastOrder &&
		momentUtc(lastOrder.processed_at, businessConfig.rechargeDateFormat)
	const subscriptionDeliveryDayName = getDeliveryDayNameFromSubscription(
		subscription
	)
	const lastOrderAttributes = lastOrder && lastOrder.line_items[0].properties
	const lastOrderDeliveryDayName = lastOrder &&
		lastOrderAttributes.find(
			attribute => attribute.name === businessConfig.deliveryDayAttributeKey
		)?.value as DayNames | undefined
	const firstDeliveryDate = getFirstDeliveryDateFromSubscription(subscription)
	const firstDeliveryMealSelection = getFirstDeliveryMealSelection(
		deliveries,
		currentDateMoment,
		firstDeliveryDate,
		firstOrder
	)
	const oneTimeDeliveryDate = getOneTimeDeliveryDateFromSubscription(subscription)
	const oneTimeDeliveryMealSelection = getOneTimeDeliveryMealSelection(
		deliveries,
		currentDateMoment,
		oneTimeDeliveryDate,
		upcomingCharge
	)
	const lastDeliveryMoment = lastOrder && getLastDeliveryMoment(
		lastOrder,
		lastChargeMoment,
		firstDeliveryDate,
		deliveries,
		subscription
	)
	const deliveryDayName = lastDeliveryMoment && getCorrectDeliveryDayName(
		subscriptionDeliveryDayName || lastOrderDeliveryDayName,
		lastDeliveryMoment.format(businessConfig.firstDeliveryDateFormat)
	)
	let mealSelections = [
		...getMealSelectionsByNextCharges(
			nextCharges,
			currentDateMoment,
			region,
			deliveries,
			deliveryDayName || undefined
		)
	]
	const lastOrderMealSelection = getLastOrderMealSelection(
		deliveries,
		currentDateMoment,
		lastOrder,
		lastDeliveryMoment
	)
	if (lastOrderMealSelection?.deliveryMoment.isAfter(currentDateMoment, 'day')) {
		mealSelections.push(lastOrderMealSelection)
	}
	if (
		firstDeliveryMealSelection?.deliveryMoment.isAfter(
			currentDateMoment,
			'day'
		)
	) {
		mealSelections.push(firstDeliveryMealSelection)
	}
	if (deliverySchedule) {
		mealSelections = [
			...getMealSelectionsByDeliverySchedule(
				deliverySchedule,
				subscription,
				currentDateMoment,
				region,
				deliveries,
				nextCharges,
				deliveryDayName || undefined
			),
			...mealSelections
		]
	}
	if (
		oneTimeDeliveryMealSelection?.deliveryMoment.isAfter(
			currentDateMoment,
			'day'
		)
	) {
		mealSelections = mealSelections.filter(mealSelection => {
			return !mealSelection.deliveryMoment.isSame(
				oneTimeDeliveryMealSelection?.deliveryMoment,
				'week'
			)
		})
		mealSelections.push(oneTimeDeliveryMealSelection)
	}
	// Sort by date
	mealSelections = mealSelections.sort((a, b) => a.deliveryMoment.diff(b.deliveryMoment))
	// Filter duplicates
	mealSelections = mealSelections.filter((mealSelection, index) => {
		const isDuplicate = mealSelections.findIndex(
			otherMealSelection => otherMealSelection.deliveryMoment.isSame(mealSelection.deliveryMoment)
		) !== index
		return !isDuplicate
	})
	// Filter by delivery skips
	if (deliverySkips && deliverySkips.length > 0) {
		mealSelections = mealSelections.filter(mealSelection => {
			const isSkipped = deliverySkips.find(
				deliverySkip =>
					mealSelection.deliveryMoment.format(
						businessConfig.deliveryDateFormat
					) === deliverySkip.deliveryDate
			)
			return !isSkipped
		})
	}
	const upcomingDeliveryMoment = mealSelections[0]?.deliveryMoment
	const upcomingMealSelection = mealSelections[0]
	return {
		firstDeliveryMoment: firstDeliveryMealSelection?.deliveryMoment || null,
		lastDeliveryMoment,
		upcomingDeliveryMoment,
		mealSelections,
		deliveryDayName: deliveryDayName || null,
		upcomingMealSelection
	}
}

export default getDeliveryInfo
