import { useRouter } from 'next/router'
import React, {
	FC,
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { useRecoilValue } from 'recoil'
import config from '~/config'
import { userRoleState } from '~/store/atoms/dashboard/userRoleState'
import { dashboardRoles } from '~/types/roles'
import LeftMenu from './Dashboard/LeftMenu'
import { PageHeader } from 'components/Dashboard/PageHeader'
import { getRegionFromLocale } from '~/helpers/locale'
import { AcceptLanguageHeaderBanner } from 'components/AcceptLanguageHeaderBanner'
import NavbarComponent from 'components/Navbar/NavbarComponent'
import { SubscribeToNewsletter } from 'components/SubscribeToNewsletter'
import { NoticeBanner } from 'components/NoticeBanner'
import { marked } from 'marked'
import dynamic from 'next/dynamic'

const MainFooter = dynamic(() => import('components/MainFooter'), {
	ssr: false
})
type ComponentProviderProps = {
	children: ReactElement[] | ReactElement
	props: any
}
const ComponentProvider: FC<ComponentProviderProps> = ({
	children,
	props
}) => {
	const [isSubscribeToNewsletterVisible, setIsSubscribeToNewsletterVisible] = useState(false)
	const [topNavbarButtons, setTopNavbarButtons] = useState(props.topNavbar?.buttons ?? [])
	const [acceptLanguageHeaderLocale, setAcceptLanguageHeaderLocale] = useState(props.acceptLanguageHeaderLocale)
	const router = useRouter()
	const userRole = useRecoilValue(userRoleState)
	const handleGoBack = useCallback(
		() => router.back(),
		[router]
	)
	const showAcceptLanguageHeaderBanner = useMemo(() => {
		return getRegionFromLocale(acceptLanguageHeaderLocale) !== getRegionFromLocale(router.locale)
	}, [acceptLanguageHeaderLocale, router.locale])

	useEffect(() => {
		const newTopNavbarButtons = props.topNavbar?.buttons ?? []
		const newAcceptLanguageHeaderLocale = props.acceptLanguageHeaderLocale
		if (newTopNavbarButtons.length > 0) {
			setTopNavbarButtons(newTopNavbarButtons)
		}
		if (newAcceptLanguageHeaderLocale) {
			setAcceptLanguageHeaderLocale(newAcceptLanguageHeaderLocale)
		}
	}, [props.acceptLanguageHeaderLocale, props.topNavbar?.buttons])

	useEffect(() => {
		setIsSubscribeToNewsletterVisible(
			router.route.split('/')[1] !== 'dashboard' &&
			router.pathname !== '/customer'
		)
	}, [router.route, router.pathname])
	if (router.route.split('/')[1] === 'dashboard') {
		return (
			<div
				className={'h-full flex w-full'}
			>
				{ userRole && dashboardRoles.includes(userRole) &&
					<LeftMenu />
				}
				<div
					style={{
						width: 'calc(100% - 230px)'
					}}
				>
					{(config.routes[router.pathname as '/dashboard/upcoming-orders'] !== undefined) && (
						<PageHeader
							onBack={handleGoBack}
							title={config.routes[router.pathname as '/dashboard/upcoming-orders']?.title || router.pathname}
							subTitle={config.routes[router.pathname as '/dashboard/upcoming-orders']?.description || ''}
							className={'border-b border-gray-200'}
						/>
					)}
					{children}
				</div>
			</div>
		)
	}

	return (
		<>
			{props.noticeBanner && (
				<NoticeBanner
					text={marked.parse(props.noticeBanner.fields.text)}
					id={props.noticeBanner.sys.id}
					color={props.noticeBanner.fields.color}
				/>
			)}
			{showAcceptLanguageHeaderBanner && (
				<AcceptLanguageHeaderBanner
					locale={acceptLanguageHeaderLocale}
				/>
			)}
			<NavbarComponent
				buttons={topNavbarButtons}
				/* Make logo smaller when top banner is visible
				/* to prevent obstruction */
				useSmallLogo={showAcceptLanguageHeaderBanner}
				hideNavigation={router.route.includes('checkout')}
			/>
			{children}
			{isSubscribeToNewsletterVisible && (
				<SubscribeToNewsletter
					className={'theContainer theContainer--no-padding-mobile'}
					wrapperClassName={'sm:pt-20 sm:pb-3 pt-0'}
				/>
			)}
			<MainFooter
				footerData={props.footerData}
			/>
		</>
	)
}

export default ComponentProvider
