const path = require('path')
const config = {
	reloadOnPrerender: process.env.NODE_ENV === 'development',
	debug: false, // process.env.NODE_ENV === 'development',
	serializeConfig: false,
	localePath: path.resolve('./public/static/locales'),
	saveMissing: true,
	i18n: {
		locales: [
			'es-ES',
			'en-ES',
			'pt-PT',
			'en-PT'
		],
		defaultLocale: 'es-ES',
		localeDetection: false,
		localePath: path.resolve('./public/static/locales')
		/*
		use: [
			new PhraseInContextEditorPostProcessor({
				phraseEnabled: process.env.NODE_ENV === 'development',
				projectId: 'faa945afe1c705d92900e6ab0578af1e'
			})
		],
		postProcess: ['phraseInContextEditor']
		 */
	}
}
module.exports = config
