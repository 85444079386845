import businessConfig from '@shared/businessConfig'
import { RechargeSubscription } from '@shared/interfaces/RechargeSubscription.interface'

export const getOneTimeDeliveryDateFromSubscription = (
	subscription: RechargeSubscription
): string | undefined => {
	return subscription.properties.find(
		attribute => attribute.name === businessConfig.oneTimeDeliveryDateAttributeKey
	)?.value
}
