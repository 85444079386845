import { Moment } from 'moment'
import { getNearestDayMoment } from './getNearestDayMoment'
import { getNextNearestDayMoment } from './getNextNearestDayMoment'
import { sortMoments } from './converters/sortMoments'
import { BusinessConfigDelivery } from '../businessConfig'

export const getNearestAvailableDeliveryDate = (
	todayDate: Moment,
	deliveries: BusinessConfigDelivery[],
	chargeMoment?: Moment
): Moment => {
	const startMoment = chargeMoment || todayDate
	const deliveriesMoments: Moment[] = []
	deliveries.forEach(delivery => {
		const nearestDeliveryMoment = getNearestDayMoment(
			delivery.deliveryDay,
			startMoment
		)
		const nextNearestDeliveryMoment = getNextNearestDayMoment(
			delivery.deliveryDay,
			startMoment
		)
		const maxValidMomentNearestDelivery = nearestDeliveryMoment.clone().subtract(
			delivery.minDaysBeforeNearestDeliveryDeadline,
			'days'
		).endOf('day')
		if (delivery.maxSelectionHour) {
			maxValidMomentNearestDelivery.set('hour', delivery.maxSelectionHour)
		}
		if (startMoment.isBefore(maxValidMomentNearestDelivery)) {
			deliveriesMoments.push(nearestDeliveryMoment)
			return
		}
		deliveriesMoments.push(nextNearestDeliveryMoment)
	})
	return sortMoments(deliveriesMoments)[0]
}
