import { selector } from 'recoil'
import { ILoyaltyAction } from '@shared/types/generated/contentful'
import { loyaltyActionsState } from './loyaltyActionsState'

export const loyaltyActionsCouponsState = selector<ILoyaltyAction[]>({
	key: 'customer:loyaltyActionsCoupons',
	get: ({ get }) => {
		const actions = get(loyaltyActionsState)
		return actions.filter(action => action.fields.type === 'coupon-creation')
	}
})
