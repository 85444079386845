import { selector } from 'recoil'
import { loyaltyChangesState } from './loyaltyChangesState'

export const loyaltyPointsState = selector<number>({
	key: 'customer:loyaltyPoints',
	get: ({ get }) => {
		const loyaltyChanges = get(loyaltyChangesState)
		return loyaltyChanges.reduce((acc, loyaltyChange) => {
			return acc + loyaltyChange.points
		}, 0)
	}
})
