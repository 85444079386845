import { db } from '~/adapters/firebase'
import {
	doc,
	onSnapshot
} from '@firebase/firestore'
import { FirebaseProfile } from '@shared/interfaces/FirebaseProfile.interface'
import { useRecoilState } from 'recoil'
import { firebaseProfileState } from '~/store/atoms/firebase/firebaseProfileState'

export const useFirebaseProfile = () => {
	const [firebaseProfile, setFirebaseProfile] = useRecoilState(firebaseProfileState)
	const subscribe = (email: string) => {
		const userDoc = doc(db, `users/${email}`)
		return onSnapshot(userDoc, snapshot => {
			const profile = snapshot.data() as FirebaseProfile
			setFirebaseProfile(profile)
		})
	}

	return {
		firebaseProfile,
		subscribe,
		clearFirebaseProfile: () => setFirebaseProfile(null)
	}
}
