import gql from 'graphql-tag'

export const customerAccessTokenCreateWithMultipassQuery = gql`
	mutation customerAccessTokenCreateWithMultipass ($multipassToken: String!) {
		customerAccessTokenCreateWithMultipass (multipassToken: $multipassToken) {
			customerAccessToken {
				accessToken
			}
			customerUserErrors {
				field
				message
			}
		}
	}
`
