import businessConfig, { BusinessConfigDelivery } from '@shared/businessConfig'
import { RechargeCharge } from '@shared/interfaces/RechargeCharge.interface'
import { Moment } from 'moment-timezone'
import { MealSelection } from '.'
import { DayNames } from '../converters/convertDayNameToNumber'
import { getFirstDeliveryDateFromCharge } from '../extractors/getFirstDeliveryDateFromCharge'
import { getDeliveryMomentsByNextCharges } from './getDeliveryMomentsByNextCharges'
import { getMealSelectionByMoment } from './getMealSelectionByMoment'

const sortCharges = (a: RechargeCharge, b: RechargeCharge) => {
	return new Date(b.scheduled_at).getTime() - new Date(a.scheduled_at).getTime()
}

export const getMealSelectionsByNextCharges = (
	nextCharges: RechargeCharge[],
	currentDateMoment: Moment,
	region: string,
	deliveries: BusinessConfigDelivery[],
	deliveryDayName?: DayNames | string
): MealSelection[] => {
	const sortedCharges = [...nextCharges].sort(sortCharges)
	const latestCharge = sortedCharges[0]
	const firstDeliveryDate = latestCharge && getFirstDeliveryDateFromCharge(latestCharge)
	const deliveryMoments = getDeliveryMomentsByNextCharges(
		nextCharges,
		currentDateMoment,
		region,
		deliveryDayName,
		firstDeliveryDate
	)
	const mealSelections: MealSelection[] = []
	deliveryMoments.forEach(deliveryMoment => {
		const deliveryCharge = nextCharges.find(charge => {
			const attributes = charge.line_items[0].properties
			return attributes.find(attribute => {
				return attribute.name === businessConfig.firstDeliveryDateAttributeKey &&
					attribute.value === deliveryMoment.format(businessConfig.firstDeliveryDateFormat)
			})
		})
		const mealSelection = getMealSelectionByMoment(
			deliveryMoment,
			deliveries,
			currentDateMoment,
			deliveryCharge ? 'paid' : 'unpaid',
			deliveryCharge
		)
		if (!mealSelection) return
		mealSelections.push(mealSelection)
	})
	return mealSelections
}
