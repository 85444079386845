import {
	useCallback,
	useEffect,
	useState
} from 'react'
import * as Sentry from '@sentry/nextjs'

interface UseAsyncWithParamsProps<Params, Response, ErrorType> {
	asyncFunction: (params: Params) => Promise<any>;
	onError?: (error: ErrorType) => void;
	onSuccess?: (data?: Response) => void;
	immediate?: true;
	initialParams?: Params;
	disableSentryCapture?: boolean;
}

type DefaultParams = {
	[key: string]: any;
}

export type UseAsyncStatus = 'idle' | 'pending' | 'success' | 'error'

export const useAsyncWithParams = <Params = DefaultParams, Response = any, ErrorType = Error>({
	asyncFunction,
	onError,
	onSuccess,
	immediate,
	initialParams,
	disableSentryCapture
}: UseAsyncWithParamsProps<Params, Response, ErrorType>) => {
	const [status, setStatus] = useState<UseAsyncStatus>('idle')
	const [value, setValue] = useState<Response | null>(null)
	const [error, setError] = useState(null)
	// The execute function wraps asyncFunction and
	// handles setting state for pending, value, and error.
	// useCallback ensures the below useEffect is not called
	// on every render, but only if asyncFunction changes.
	const execute = useCallback((params: Params) => {
		setStatus('pending')
		setValue(null)
		setError(null)
		return asyncFunction(params)
			.then((response) => {
				setValue(response)
				setStatus('success')
				onSuccess?.(response)
			})
			.catch((error) => {
				if (!disableSentryCapture) Sentry.captureException(error)
				setError(error)
				setStatus('error')
				onError?.(error)
			})
	}, [asyncFunction, disableSentryCapture, onError, onSuccess])

	useEffect(() => {
		if (!immediate) return
		execute(initialParams)
	}, [execute, immediate, initialParams])

	return {
		execute,
		status,
		value,
		error
	}
}
