import businessConfig from '@shared/businessConfig'
import momentUtc from '@shared/helpers/momentUtc'
import { Moment } from 'moment'
import { Order } from '@rechargeapps/storefront-client'

export const getLastDeliveryMomentFromRechargeOrder = (
	order?: Order
): Moment | null => {
	if (
		!order ||
		!order.tags
	) return null
	const lastDeliveryDateTag = order.tags.split(', ').find(tag => tag.includes(businessConfig.deliveryDateTagPrefix))
	const lastDeliveryDate = lastDeliveryDateTag &&
		lastDeliveryDateTag.split('_')[1]
	if (!lastDeliveryDate) return null
	return momentUtc(lastDeliveryDate, businessConfig.deliveryDateTagFormat)
}
