import axios from 'axios'
import crypto from 'crypto'
import businessConfig from '@shared/businessConfig'
import * as Sentry from '@sentry/nextjs'

export const getServerSideFeatureFlags = async (
	userId?: string
): Promise<Record<string, string | boolean>> => {
	try {
		const randomNumber = crypto.randomBytes(16).toString('hex')
		const safeUserId = userId || ('anonymous_' + randomNumber)
		const { data }: any = await axios.post(
			'https://app.posthog.com/decide?v=2',
			{
				api_key: businessConfig.posthogToken,
				distinct_id: safeUserId
			}
		)

		return data.featureFlags
	} catch (error) {
		Sentry.captureException(error, {
			level: 'fatal',
			tags: {
				source: 'getServerSideFeatureFlags'
			}
		})
		return {
		}
	}
}
