import {
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { useRecoilState } from 'recoil'
import { useToast } from '@chakra-ui/toast'
import { useAsyncWithParams } from '~/hooks/useAsyncWithParams'
import { useTranslation } from 'next-i18next'
import { addDiscountCodeStatusState } from '~/store/atoms/checkout/addDiscountCodeStatusState'
import { discountCodeState } from '~/store/atoms/checkout/discountCodeState'
import { useRouter } from 'next/router'
import { getDiscountCodeByCode } from '~/adapters/shopify/getDiscountCodeByCode'
import { activeDiscountDataState } from '~/store/atoms/checkout/activeDiscountDataState'
import { useLocalStorage } from 'usehooks-ts'

export interface SimpleDiscount {
	type: 'percentage' | 'amount'
	amount: number;
	recurringCycleLimit: number;
}

export const useDiscountCode = () => {
	const { t } = useTranslation()
	const router = useRouter()
	const toast = useToast()
	const [, setAppliedReferralCode] = useLocalStorage('appliedReferralCode', null)
	const [discountCodeInput, setDiscountCodeInput] = useState('')
	const [discountCode, setDiscountCode] = useRecoilState(discountCodeState)
	const [addDiscountCodeStatus, setAddDiscountCodeStatus] = useRecoilState(addDiscountCodeStatusState)
	const [activeDiscountData, setActiveDiscountData] = useRecoilState(activeDiscountDataState)
	const _addDiscountCode = useCallback(async (
		params: {
			discountCode: string;
			showNotification?: boolean;
		}
	) => {
		try {
			const discountData = await getDiscountCodeByCode(params.discountCode)
			setDiscountCodeInput('')
			setActiveDiscountData(discountData)
			if (params.showNotification) {
				toast({
					title: t('notifications:addDiscountCodeSuccessTitle'),
					description: t('notifications:addDiscountCodeSuccessDescription'),
					status: 'success'
				})
			}
			setDiscountCode(params.discountCode)
		} catch (error) {
			if (params.showNotification) {
				toast({
					title: t('notifications:addDiscountCodeErrorTitle'),
					description: t('notifications:addDiscountCodeErrorDescription'),
					status: 'error'
				})
			}
		}
	}, [setActiveDiscountData, setDiscountCode, t, toast])
	const resetDiscountCode = async (onSuccess?: () => void) => {
		if (router.query.discount) {
			const query = {
				...router.query
			}
			delete query.discount
			await router.replace(router.pathname, {
				query
			}, {
				shallow: true
			})
		}
		setDiscountCode(null)
		setActiveDiscountData(null)
		setAddDiscountCodeStatus('idle')
		setAppliedReferralCode(null)
		if (onSuccess) onSuccess()
	}
	const {
		execute: addDiscountCode,
		status: _addDiscountCodeStatus
	} = useAsyncWithParams<{ discountCode: string; showNotification?: boolean; }, Promise<void>>({
		asyncFunction: _addDiscountCode
	})
	const recurringCycleLimit = useMemo(() => {
		if (!activeDiscountData) {
			return 0
		}
		return activeDiscountData.shopifyDiscount.recurringCycleLimit
	}, [activeDiscountData])

	useEffect(() => {
		setAddDiscountCodeStatus(_addDiscountCodeStatus)
	}, [
		_addDiscountCodeStatus,
		setAddDiscountCodeStatus
	])
	useEffect(() => {
		// Reset add discount code status on discount code change
		if (discountCode !== null) {
			setAddDiscountCodeStatus('idle')
		}
	}, [
		discountCode,
		setAddDiscountCodeStatus,
		addDiscountCodeStatus
	])
	useEffect(() => {
		if (
			discountCode &&
			!activeDiscountData &&
			addDiscountCodeStatus === 'idle'
		) {
			addDiscountCode({
				discountCode
			}).catch(() => {})
		}
	}, [discountCode, activeDiscountData, addDiscountCodeStatus, addDiscountCode])

	const activeDiscount = useMemo<SimpleDiscount>(
		() => {
			if (!activeDiscountData) {
				return null
			}
			const value = activeDiscountData.shopifyDiscount.customerGets.value
			const type = value.percentage ? 'percentage' : 'amount'
			return {
				type,
				amount: value.percentage ? value.percentage * 100 : value.amount.amount,
				recurringCycleLimit: activeDiscountData.shopifyDiscount.recurringCycleLimit || 0
			}
		},
		[activeDiscountData]
	)

	return {
		addDiscountCode,
		addDiscountCodeStatus,
		recurringCycleLimit,
		resetDiscountCode,
		discountCode,
		discountCodeInput,
		setDiscountCodeInput,
		activeDiscount,
		activeDiscountData
	}
}
