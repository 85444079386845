import {
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import XIcon from 'assets/icons/x.svg'
import { getRegionFromLocale } from '~/helpers/locale'
import { Button } from '@chakra-ui/react'
import { useLocaleSelector } from '~/hooks/useLocaleSelector'
import {
	getCookie,
	setCookie
} from 'cookies-next'

type AcceptLanguageHeaderBannerProps = {
	locale: string
}

export const AcceptLanguageHeaderBanner: FC<AcceptLanguageHeaderBannerProps> = ({ locale }) => {
	const [bannerHidden, setBannerHidden] = useState(true)
	const { t } = useTranslation()
	const handleBannerClose = useCallback(() => {
		setBannerHidden(true)
		setCookie('acceptLanguageHeaderBannerHidden', 'true')
	}, [])
	const { selectLocale } = useLocaleSelector()
	const newRegion = useMemo(
		() => getRegionFromLocale(locale),
		[locale]
	)
	const handleSelectNewRegion = useCallback(() => {
		selectLocale({
			location: newRegion
		})
	}, [newRegion, selectLocale])

	useEffect(() => {
		const acceptLanguageHeaderBannerHiddenCookie = getCookie('acceptLanguageHeaderBannerHidden')
		setBannerHidden(acceptLanguageHeaderBannerHiddenCookie?.toString() === 'true')
	}, [])
	return (
		<div
			className={classNames(
				'absolute top-0',
				'w-full sm:h-14 bg-blue-100 flex-col sm:flex-row items-center sm:justify-center',
				'font-semibold border-t border-blue-200 border-b text-center px-1 py-2 z-30',
				bannerHidden ? 'hidden' : 'flex'
			)}
		>
			<p
				className={'text-blue-700'}
			>
				{ t('common:acceptLanguageHeaderBanner.text.' + newRegion.toUpperCase()) }
			</p>
			<Button
				className={'ml-0 sm:ml-4 mt-2 sm:mt-0'}
				colorScheme={'blue'}
				onClick={handleSelectNewRegion}
			>
				{ t('common:acceptLanguageHeaderBanner.button.' + newRegion.toUpperCase()) }
			</Button>
			<button
				className={'flex mr-4 cursor-pointer absolute right-0 bottom-4 sm:bottom-auto items-center p-1'}
				onClick={handleBannerClose}
			>
				<XIcon
					width={17}
					height={17}
					className={'hover:opacity-75 duration-100'}
				/>
			</button>
		</div>
	)
}
