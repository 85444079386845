import { Days } from './enums/days.enum'
import { Moment, unitOfTime } from 'moment'
import { getNearestDayMoment } from './helpers/getNearestDayMoment'
import getCurrentMoment from './helpers/getCurrentMoment'

const sortByMoment = (a: Moment, b: Moment) => {
	return a.diff(b)
}

export type BusinessConfigDelivery = {
	deliveryDay: Days,
	chargeDay: Days,
	daysToChargeAfterDelivery: number
	daysToChargeBeforeDelivery: number
	isStart: boolean
	maxSelectionHour?: number;
	minDaysBeforeNearestDeliveryDeadline?: number
	maxDaysBeforeMealSelection?: number
	minDaysBeforeMealSelection?: number
	daysBeforePacking?: number
	zipcodesList?: 'monday'
	differenceToPreviousConfigCharge: number
}

export type BusinessConfig = {
	nullCountryFallback: string;
	currencyCode: string;
	baseMealProductHandle: string;
	firstDeliveryDateAttributeKey: string;
	firstDeliveryDateFormat: string;
	oneTimeDeliveryDateAttributeKey: string;
	oneTimeDeliveryDateFormat: string;
	oneTimeDeliveryDayAttributeKey: string;
	deliveryDayAttributeKey: string;
	daysAfterFirstDeliveryCharge: number;
	orderOriginAttributeValue: string;
	testOrderAttributeKey: string;
	testOrderAttributeValue: string;
	subscriptionIdTagPrefix: string;
	testOrderTag: string;
	preferredEmailsLanguageOptions: { value: string; label: string; }[];
	newUserKlaviyoListId: string;
	showCancelledSubscriptionsYoungerThanDays: number,
	selectYourMealsNewMembersKlaviyoListId: string;
	minDaysBeforeFirstDeliveryDate: number;
	deliveriesByRegion: {
		[region: string]: BusinessConfigDelivery[]
	}
	maxNumberOfEachMeal: number;
	maxNumberOfEachUpsell: number;
	rechargeDateFormat: string;
	contentfulDateFormat: string;
	upsellProductsHandles: string[];
	deliveryDateFormat: string;
	temporarilyHiddenRegions: string[];
	storefrontAccessToken: string;
	storefrontUrl: string;
	planProductsIds: string[];
	newWebsiteSalesChannelId: string,
	defaultPlanFrequency: number;
	defaultOrderIntervalUnit: unitOfTime.DurationConstructor;
	allRegions: string[];
	availableRegions: string[];
	sumMealsInVariantTagPrefix: string;
	sumMealsByProductId: {
		[id: number]: number
	};
	sumMealsByVariantId: {
		[id: number]: number
	};
	deliveryDateTagPrefix: string;
	deliveryDateTagFormat: string;
	deliveryCountryTagPrefix: string;
	numberOfPreviousDeliveryWeeks: number;
	numberOfDeliveryWeeks: number;
	deliveryDaysMoments: Moment[];
	allRegionsTag: string;
	exportFileQuerySeparator: string;
	// Indexes of default selected meal plan options
	defaultSelectedPlanOptions: {
		index: number;
		id: string;
	}[];
	timezoneByRegion: {
		[region: string]: string
	}
	posthogToken: string;
	closestNewVariantIdByOldProductId: {
		[id: number]: number;
	};
	checkoutDomain: string;
	minDaysBeforeDeliverySkip: number;
	fbcAttributeName: string;
	fbpAttributeName: string;
	mealsLimitDateFormat: string;
	rechargeStoreIdentifier: string;
	rechargeStorefrontAccessToken: string;
	shippingTimeslotAttributeKey: string;
	shippingTimeslotTagPrefix: string;
	shippingFeeProductsIds: string[];
	reactivationsQueueCollectionId: string;
	taxRateByRegion: Record<string, number>;
	newFunnelEnabled: boolean;
}

const businessConfig: BusinessConfig = {
	nullCountryFallback: 'es',
	currencyCode: 'EUR',
	baseMealProductHandle: 'plans-vfc',
	firstDeliveryDateAttributeKey: 'DeliveryDate',
	firstDeliveryDateFormat: 'DD/MM/YYYY',
	oneTimeDeliveryDateAttributeKey: 'OneTimeDeliveryDate',
	oneTimeDeliveryDateFormat: 'DD/MM/YYYY',
	oneTimeDeliveryDayAttributeKey: 'OneTimeDeliveryDay',
	deliveryDayAttributeKey: 'DeliveryDay',
	testOrderAttributeKey: 'Test order do not process',
	testOrderAttributeValue: 'true',
	orderOriginAttributeValue: 'new-website',
	minDaysBeforeFirstDeliveryDate: 2,
	daysAfterFirstDeliveryCharge: 2,
	showCancelledSubscriptionsYoungerThanDays: 7,
	selectYourMealsNewMembersKlaviyoListId: 'UpsjzP',
	newUserKlaviyoListId: 'YaSWRZ',
	subscriptionIdTagPrefix: 'recharge-subscriptionId_',
	testOrderTag: 'test-order-do-not-process',
	newWebsiteSalesChannelId: '305397761',
	preferredEmailsLanguageOptions: [
		{
			value: 'en',
			label: 'English'
		},
		{
			value: 'es',
			label: 'Español'
		},
		{
			value: 'pt',
			label: 'Português'
		}
	],
	// Deliveries needs to be in the correct order by the delivery day
	// in the week, so we can create ranges of delivery dates
	deliveriesByRegion: {
		es: [{
			chargeDay: Days.SUNDAY,
			deliveryDay: Days.FRIDAY,
			minDaysBeforeNearestDeliveryDeadline: 3,
			maxDaysBeforeMealSelection: 11,
			minDaysBeforeMealSelection: 2,
			daysToChargeAfterDelivery: 2,
			daysToChargeBeforeDelivery: 5,
			daysBeforePacking: 1,
			isStart: true,
			differenceToPreviousConfigCharge: -2
		},
		{
			chargeDay: Days.WEDNESDAY,
			deliveryDay: Days.MONDAY,
			minDaysBeforeNearestDeliveryDeadline: 3,
			maxDaysBeforeMealSelection: 11,
			minDaysBeforeMealSelection: 2,
			daysToChargeAfterDelivery: 2,
			daysToChargeBeforeDelivery: 5,
			daysBeforePacking: 1,
			isStart: false,
			zipcodesList: 'monday',
			maxSelectionHour: 10,
			differenceToPreviousConfigCharge: -2
		},
		{
			chargeDay: Days.THURSDAY,
			deliveryDay: Days.TUESDAY,
			minDaysBeforeNearestDeliveryDeadline: 3,
			maxDaysBeforeMealSelection: 11,
			minDaysBeforeMealSelection: 2,
			daysToChargeAfterDelivery: 2,
			daysToChargeBeforeDelivery: 5,
			daysBeforePacking: 1,
			isStart: false,
			differenceToPreviousConfigCharge: -1
		},
		{
			chargeDay: Days.FRIDAY,
			deliveryDay: Days.WEDNESDAY,
			minDaysBeforeNearestDeliveryDeadline: 3,
			maxDaysBeforeMealSelection: 11,
			minDaysBeforeMealSelection: 2,
			daysToChargeAfterDelivery: 2,
			daysToChargeBeforeDelivery: 5,
			daysBeforePacking: 1,
			isStart: false,
			differenceToPreviousConfigCharge: 0
		}],
		pt: [{
			chargeDay: Days.SUNDAY,
			deliveryDay: Days.FRIDAY,
			minDaysBeforeNearestDeliveryDeadline: 3,
			maxDaysBeforeMealSelection: 11,
			minDaysBeforeMealSelection: 2,
			daysToChargeAfterDelivery: 2,
			daysToChargeBeforeDelivery: 5,
			daysBeforePacking: 1,
			isStart: true,
			differenceToPreviousConfigCharge: -2
		},
		{
			chargeDay: Days.THURSDAY,
			deliveryDay: Days.TUESDAY,
			minDaysBeforeNearestDeliveryDeadline: 3,
			maxDaysBeforeMealSelection: 11,
			minDaysBeforeMealSelection: 2,
			daysToChargeAfterDelivery: 2,
			daysToChargeBeforeDelivery: 5,
			daysBeforePacking: 1,
			isStart: false,
			differenceToPreviousConfigCharge: -1
		},
		{
			chargeDay: Days.FRIDAY,
			deliveryDay: Days.WEDNESDAY,
			minDaysBeforeNearestDeliveryDeadline: 3,
			maxDaysBeforeMealSelection: 11,
			minDaysBeforeMealSelection: 2,
			daysToChargeAfterDelivery: 2,
			daysToChargeBeforeDelivery: 5,
			daysBeforePacking: 1,
			isStart: false,
			differenceToPreviousConfigCharge: 0
		}]
	},
	maxNumberOfEachMeal: 3,
	maxNumberOfEachUpsell: 3,
	rechargeDateFormat: 'YYYY-MM-DD',
	contentfulDateFormat: 'YYYY-MM-DD',
	deliveryDateFormat: 'YYYY-MM-DD',
	upsellProductsHandles: [
		'test-addon',
		'test-addon-2'
	],
	storefrontAccessToken: 'b0f19d89460aa50dfc56675afe83bcb7',
	storefrontUrl: 'https://veganfood-club.myshopify.com/api/2022-10/graphql.json',
	planProductsIds: [
		'7795106152680'
	],
	defaultPlanFrequency: 7,
	defaultOrderIntervalUnit: 'days',
	temporarilyHiddenRegions: [],
	allRegions: [
		'ES',
		'PT',
		'AD'
	],
	availableRegions: [
		'PT',
		'ES'
	],
	sumMealsInVariantTagPrefix: 'sum-meals_',
	sumMealsByProductId: {
		5833416605853: 5,
		5831286947997: 10,
		6544935125149: 5
	},
	sumMealsByVariantId: {
		43200481788136: 4,
		43200481820904: 5,
		43200481853672: 6,
		43200481886440: 7,
		43200481919208: 8,
		43200481951976: 8,
		48014155120979: 10,
		43200481984744: 10,
		43200482017512: 12,
		43200482050280: 14,
		43200482083048: 16,
		48014155219283: 20
	},
	deliveryDateTagPrefix: 'd-test_',
	deliveryDateTagFormat: 'DD-MM-YYYY',
	deliveryCountryTagPrefix: 'delivery-country_',
	// Dashboard
	// Firestore accepts maximum 10 comparisons in one query
	// Don't change those values, it may break the dashboard
	numberOfPreviousDeliveryWeeks: 10,
	numberOfDeliveryWeeks: 3,
	deliveryDaysMoments: [
		getNearestDayMoment(Days.MONDAY, getCurrentMoment()),
		getNearestDayMoment(Days.TUESDAY, getCurrentMoment()),
		getNearestDayMoment(Days.WEDNESDAY, getCurrentMoment()),
		getNearestDayMoment(Days.FRIDAY, getCurrentMoment())
	].sort(sortByMoment),
	allRegionsTag: 'All',
	exportFileQuerySeparator: '?',
	defaultSelectedPlanOptions: [
		{
			id: 'gid://shopify/ProductOption/9952009748712',
			index: 0
		},
		{
			id: 'gid://shopify/ProductOption/9952009781480',
			index: 1
		}
	],
	timezoneByRegion: {
		FR: 'Europe/Paris',
		ES: 'Europe/Madrid',
		PT: 'Europe/Lisbon',
		AD: 'Europe/Andorra',
		BE: 'Europe/Brussels'
	},
	posthogToken: 'phc_z1eJE5C5siAWxLta5m7PLYsK2HDSLz30gOvSxsPLISY',
	closestNewVariantIdByOldProductId: {
		5833416605853: 43200481820904,
		6544935125149: 43200481820904,
		5831286947997: 43200481984744
	},
	checkoutDomain: 'checkout.freshgreens.es',
	minDaysBeforeDeliverySkip: 5,
	fbcAttributeName: 'fbc',
	fbpAttributeName: 'fbp',
	mealsLimitDateFormat: 'YYYY-MM-DD',
	rechargeStoreIdentifier: 'veganfood-club.myshopify.com',
	rechargeStorefrontAccessToken: 'strfnt_4652257ed56f2302c785c898007917e16f709419c7e7f79fa4984bddb31cdaaa',
	shippingTimeslotAttributeKey: 'ShippingTimeslot',
	shippingTimeslotTagPrefix: 'shipping-timeslot_',
	shippingFeeProductsIds: ['8712759345491'],
	reactivationsQueueCollectionId: 'reactivationsQueue',
	taxRateByRegion: {
		ES: 0.1,
		PT: 0.13
	},
	newFunnelEnabled: false
}

export default businessConfig
