import {
	DayNames,
	getIsDayNames
} from '@shared/helpers/converters/convertDayNameToNumber'
import { getDayNameFromDate } from '@shared/helpers/extractors/getDayNameFromDate'

export const getCorrectDeliveryDayName = (
	deliveryDayName?: DayNames | string | undefined,
	firstDeliveryDate?: string | undefined
): DayNames | undefined => {
	let correctDeliveryDayName: DayNames | undefined
	if (deliveryDayName && getIsDayNames(deliveryDayName)) {
		correctDeliveryDayName = deliveryDayName
	}
	const firstDeliveryDayName = firstDeliveryDate ? getDayNameFromDate(firstDeliveryDate) : undefined
	return correctDeliveryDayName || firstDeliveryDayName
}
