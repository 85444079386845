import { atom } from 'recoil'
import { IGlobalSettingsFields } from '@shared/types/generated/contentful'
import { DashboardSettings } from '@shared/interfaces/Settings.interface'

export const globalSettingsState = atom<IGlobalSettingsFields>({
	key: 'globalSettings',
	default: {
		title: 'Global Settings',
		loyaltySpendLimit: 0,
		tagsByZipcode: []
	}
})

export const dashboardSettingsState = atom<DashboardSettings>({
	key: 'dashboardSettingsState',
	default: {
		disabledDeliveryDatesByRegion: {
		},
		checkoutCalendarNotice: null
	}
})

export const isLoyaltyDialogOpenState = atom<boolean>({
	key: 'isLoyaltyDialogOpenState',
	default: false
})
