import { useRecoilState } from 'recoil'
import { multipassTokenState } from '~/store/atoms/customer/multipassTokenState'
import { multipassTokenLoadingState } from '~/store/atoms/customer/multipassTokenLoadingState'
import { useCallback } from 'react'
import { getMultipassToken } from '~/adapters/shopify/getMultipassToken'
import * as Sentry from '@sentry/nextjs'

export const useMultipass = () => {
	const [multipassToken, setMultipassToken] = useRecoilState(multipassTokenState)
	const [multipassTokenLoading, setMultipassTokenLoading] = useRecoilState(multipassTokenLoadingState)
	const clearMultipassToken = useCallback(() => {
		setMultipassToken(null)
	}, [setMultipassToken])
	const fetchMultipassToken = useCallback(async () => {
		if (multipassTokenLoading) {
			console.warn('Multipass token is already being fetched')
			return
		}
		try {
			setMultipassTokenLoading(true)
			const multipassToken = await getMultipassToken()
			setMultipassToken(multipassToken)
			return multipassToken
		} catch (error) {
			Sentry.captureException(error, {
				level: 'error',
				extra: {
					description: 'Failed to fetch multipass token'
				},
				tags: {
					source: 'fetchMultipassToken'
				}
			})
			return null
		} finally {
			setMultipassTokenLoading(false)
		}
	}, [multipassTokenLoading, setMultipassToken, setMultipassTokenLoading])

	return {
		multipassToken,
		multipassTokenLoading,
		fetchMultipassToken,
		clearMultipassToken
	}
}
