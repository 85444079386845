import { i18n } from '~/next-i18next.config.js'
const defaultLocale = i18n.defaultLocale

export const getLanguageFromLocale = (
	locale: string = defaultLocale
) => locale.split('-')[0]

export const getRegionFromLocale = (
	locale: string = defaultLocale
) => locale.split('-')[1]?.toLowerCase()

