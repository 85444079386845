import { useRecoilValue } from 'recoil'
import apolloStorefrontClient from '@shared/adapters/apolloStorefrontClient'
import { useAsync } from '~/hooks/useAsync'
import { customerQuery } from '~/queries/customer.query'
import {
	useCallback,
	useContext,
	useEffect,
	useMemo
} from 'react'
import {
	getLanguageFromLocale,
	getRegionFromLocale
} from '~/helpers/locale'
import businessConfig from '@shared/businessConfig'
import { selectedLocaleState } from '~/store/atoms/checkout/selectedLocaleState'
import { UserContext } from '~/hocs/UserContextProvider'
import { useMultipass } from '~/hooks/customer/useMultipass'
import { useCustomerAccessToken } from '~/hooks/customer/useCustomerAccessToken'

export const useCustomer = () => {
	const {
		customerData,
		setCustomerData,
		setCustomerDataStatus,
		customerAccessTokenLoading,
		customerDataStatus
	} = useContext(UserContext)
	const {
		clearMultipassToken,
		multipassToken,
		multipassTokenLoading
	} = useMultipass()
	const {
		customerAccessToken,
		clearCustomerAccessToken,
		fetchCustomerAccessToken
	} = useCustomerAccessToken()
	const customerAddresses = useMemo(() => customerData?.addresses.edges ?? [], [customerData])
	const locale  = useRecoilValue(selectedLocaleState)
	const clearCustomer = useCallback(() => {
		clearMultipassToken()
		clearCustomerAccessToken()
		setCustomerData(null)
	}, [clearMultipassToken, clearCustomerAccessToken, setCustomerData])
	const region = useMemo(() => {
		return getRegionFromLocale(locale)
	}, [locale])
	const language = useMemo(() => {
		return getLanguageFromLocale(locale)
	}, [locale])
	const _fetchCustomerData = useCallback(async () => {
		const customerAccessTokenVariable = customerAccessToken ?? await fetchCustomerAccessToken()
		if (!customerAccessTokenVariable) {
			return null
		}
		return await apolloStorefrontClient.mutate({
			mutation: customerQuery,
			variables: {
				customerAccessToken: customerAccessTokenVariable,
				language: language.toUpperCase() || businessConfig.nullCountryFallback.toUpperCase(),
				country: region.toUpperCase() || businessConfig.nullCountryFallback.toUpperCase()
			}
		})
	}, [customerAccessToken, fetchCustomerAccessToken, language, region])
	const {
		execute: fetchCustomerData,
		status: _customerDataStatus
	} = useAsync({
		asyncFunction: _fetchCustomerData,
		onSuccess: (data) => {
			setCustomerData(data?.data?.customer)
		},
		onError: (error) => {
			console.error(error)
		}
	})

	useEffect(() => {
		setCustomerDataStatus(_customerDataStatus)
	}, [
		_customerDataStatus,
		setCustomerDataStatus
	])

	return {
		multipassToken,
		customerAccessToken,
		customerData,
		multipassTokenLoading,
		customerAccessTokenLoading,
		customerDataStatus,
		fetchCustomerData,
		fetchCustomerDataAsync: _fetchCustomerData,
		clearCustomer,
		fetchCustomerAccessToken,
		customerAddresses
	}
}
