import getCurrentMoment from '@shared/helpers/getCurrentMoment'
import { DashboardSettings } from '@shared/interfaces/Settings.interface'
import businessConfig from '@shared/businessConfig'
import { handleFilterDeliveryDate } from './validators/handleFilterDeliveryDate'
import { IGlobalSettingsFields } from '~/functions/src/shared/types/generated/contentful'

export const getDefaultSelectedDeliveryDate = (
	dashboardSettings: DashboardSettings,
	globalSettings: IGlobalSettingsFields,
	region: string,
	postalCode?: string
) => {
	// Max tries that loop will run to find the first available delivery date
	const maxTriesToFindDeliveryDate = 50
	let startDate = getCurrentMoment(businessConfig.timezoneByRegion[region.toUpperCase()])
	// Iterate through the delivery dates and find the first one that is available
	for (
		let tries = 0;
		tries < maxTriesToFindDeliveryDate;
		tries++
	) {
		startDate.add(1, 'day')
		if (
			handleFilterDeliveryDate(
				startDate.local().toDate(),
				businessConfig.deliveriesByRegion[region],
				dashboardSettings,
				region,
				globalSettings,
				postalCode
			)
		) {
			return startDate.format('DD/MM/YYYY')
		}
	}
}
