import {
	useCallback,
	useState
} from 'react'
import { useRecoilState } from 'recoil'
import { getCustomerDeliverySchedule } from '~/adapters/subscriptions/getCustomerDeliverySchedule'
import { customerDeliveryScheduleState } from '~/store/atoms/recharge/customerDeliveryScheduleState'
import { useRechargeApi } from '../useRechargeApi'
import { Session } from '@rechargeapps/storefront-client'

export const useCustomerDeliverySchedule = () => {
	const { rechargeSession } = useRechargeApi()
	const [isLoading, setIsLoading] = useState(false)
	const [customerDeliverySchedule, setCustomerDeliverySchedule] = useRecoilState(customerDeliveryScheduleState)
	const fetchCustomerDeliverySchedule = useCallback((session?: Session) => {
		setCustomerDeliverySchedule(null)
		setIsLoading(true)
		getCustomerDeliverySchedule({
			session: session ?? rechargeSession
		}).then(response => {
			setCustomerDeliverySchedule(response)
		}).finally(() => {
			setIsLoading(false)
		})
	}, [
		setCustomerDeliverySchedule,
		rechargeSession
	])

	return {
		customerDeliverySchedule,
		fetchCustomerDeliverySchedule,
		isLoading
	}
}
