import { Button } from '@chakra-ui/button'
import React, {
	FC,
	useCallback,
	useEffect
} from 'react'
import { useLogOut } from '~/hooks/useLogOut'
import {
	useRecoilState,
	useRecoilValue
} from 'recoil'
import { useTranslation } from 'next-i18next'
import { loyaltyPointsState } from '~/store/atoms/customer/loyaltyPointsState'
import { useRouter } from 'next/router'
import { NavbarPopover } from 'components/Navbar/NavbarPopover'
import Link from 'next/link'
import { isLoyaltyDialogOpenState } from '~/store/global'
import { useAuthState } from '~/hooks/useAuthState'

type NavbarUserMenuProps = {
	initialLoyaltyDialogState?: boolean;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	loyaltyDialogEnabled: boolean;
	className?: string
}

const NavbarUserMenu: FC<NavbarUserMenuProps> = ({
	initialLoyaltyDialogState = false,
	isOpen,
	setIsOpen,
	loyaltyDialogEnabled,
	className
}) => {
	const {
		pathname,
		replace,
		query
	} = useRouter()
	const { t } = useTranslation()
	const [user] = useAuthState()
	const loyaltyPoints = useRecoilValue(loyaltyPointsState)
	const {
		logOut,
		isLoading
	} = useLogOut()
	const handleOpen = useCallback(() => {
		setIsOpen(true)
	}, [setIsOpen])
	const handleClose = useCallback(() => {
		setIsOpen(false)
	}, [setIsOpen])
	const [, setIsLoyaltyDialogOpen] = useRecoilState(isLoyaltyDialogOpenState)

	useEffect(() => {
		if (!user) return
		if (!initialLoyaltyDialogState) return
		setIsLoyaltyDialogOpen(initialLoyaltyDialogState)
		const copiedQuery = {
			...query
		}
		delete copiedQuery.loyaltyDialogOpen
		replace(pathname, copiedQuery, {
			shallow: true
		})
	}, [initialLoyaltyDialogState, user, pathname, query, replace, setIsLoyaltyDialogOpen])

	const handleLogOut = useCallback(() => {
		logOut({
			close: handleClose
		})
	}, [logOut, handleClose])
	const handleOpenLoyaltyDialog = useCallback(() => {
		setIsLoyaltyDialogOpen(true)
		handleClose()
	}, [handleClose, setIsLoyaltyDialogOpen])

	return (
		<div
			className={className}
		>
			<div
				className={'relative'}
			>
				<Button
					variant={'solid'}
					colorScheme={'secondary'}
					onClick={isOpen ? handleClose : handleOpen}
					style={{
						fontSize: '0.9rem'
					}}
				>
					<span>
						{
							user ? (user.displayName || user.email) : t('customer:notLoggedInText')
						}
					</span>
				</Button>
				<NavbarPopover
					isOpen={isOpen}
					onClose={handleClose}
				>
					<p
						className={'mx-3 mt-1 mb-2 text-sm font-semibold'}
					>
						{ t('common:profile') }
					</p>
					{user ? (
						<>
							<Link
								href={'/customer'}
								passHref={true}
								onClick={handleClose}
							>
								<Button
									variant={'ghost'}
									className={'w-full'}
									justifyContent={'flex-start'}
									size={'sm'}
									fontWeight={'normal'}
								>
									{ t('common:yourSubscriptions') }
								</Button>
							</Link>
							{loyaltyDialogEnabled && (
								<Button
									variant={'ghost'}
									className={'w-full'}
									justifyContent={'flex-start'}
									onClick={handleOpenLoyaltyDialog}
									size={'sm'}
									fontWeight={'normal'}
								>
									{ t('common:referAFriendButtonText') }{' '}
									{'('}{t('loyalty:points', {
										points: loyaltyPoints
									})}{')'}
								</Button>
							)}
							<Button
								variant={'ghost'}
								colorScheme={'red'}
								className={'w-full'}
								justifyContent={'flex-start'}
								onClick={handleLogOut}
								isLoading={isLoading}
								size={'sm'}
							>
								{ t('common:logOut') }
							</Button>
						</>
					) : (
						<>
							<Link
								href={'/customer/sign-in'}
								passHref={true}
								onClick={handleClose}
							>
								<Button
									variant={'ghost'}
									className={'w-full'}
									justifyContent={'flex-start'}
									size={'sm'}
									fontWeight={'normal'}
								>
									{ t('common:signIn') }
								</Button>
							</Link>
							<Link
								href={'/customer/sign-up'}
								passHref={true}
								onClick={handleClose}
							>
								<Button
									variant={'ghost'}
									className={'w-full'}
									justifyContent={'flex-start'}
									size={'sm'}
									fontWeight={'normal'}
								>
									{ t('common:signUp') }
								</Button>
							</Link>
						</>
					)}
				</NavbarPopover>
			</div>
		</div>
	)
}

export default NavbarUserMenu
