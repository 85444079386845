import { UserRecord } from 'firebase-admin/auth'
import { useContext } from 'react'
import { useRecoilValue } from 'recoil'
import { UserContext } from '~/hocs/UserContextProvider'
import { userLoadingState } from '~/store/atoms/firebase/userLoadingState'

export const useAuthState = (): [UserRecord | undefined, boolean] => {
	const userContext = useContext(UserContext)
	const loading = useRecoilValue(userLoadingState)
	return [
		userContext.user,
		loading
	]
}
