import { Moment } from 'moment'

export const getNearestDayMoment = (
	day: number, dateMoment: Moment
): Moment => {
	const cloneDateMoment = dateMoment.clone()
	const dateDay = cloneDateMoment.isoWeekday()
	if (dateDay <= day) return cloneDateMoment.isoWeekday(day)
	return cloneDateMoment.add(1, 'weeks').isoWeekday(day)
}
