import {
	ApolloClient,
	HttpLink,
	InMemoryCache
} from '@apollo/client/core'
import businessConfig from '@shared/businessConfig'
import fetch from 'cross-fetch'

const client = new ApolloClient({
	link: new HttpLink({
		uri: businessConfig.storefrontUrl,
		fetch,
		headers: {
			'X-Shopify-Storefront-Access-Token': businessConfig.storefrontAccessToken,
			'Content-Type': 'application/json'
		}
	}),
	cache: new InMemoryCache()
})

export default client
