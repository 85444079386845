import { useAsyncWithParams } from '~/hooks/useAsyncWithParams'
import { auth } from '~/adapters/firebase'
import { MutableRefObject } from 'react'
import { useToast } from '@chakra-ui/toast'
import { useRouter } from 'next/router'

type CloseFunction = (focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>) => void

type LogOutParams = {
	close?: CloseFunction
} | undefined

export const useLogOut = () => {
	const router = useRouter()
	const toast = useToast()
	const {
		execute,
		status
	} = useAsyncWithParams<LogOutParams>({
		asyncFunction: async (params) => {
			await auth.signOut()
			if (params.close) params.close()
		},
		onSuccess: () => {
			router.push('/')
		},
		onError: (error) => {
			toast({
				title: 'Error',
				description: error.message,
				status: 'error'
			})
			console.error(error)
		}
	})

	return {
		logOut: (params?: LogOutParams) => execute(params),
		isLoading: status === 'pending'
	}
}
