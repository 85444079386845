import { RechargeCharge } from '@shared/interfaces/RechargeCharge.interface'
import { getCorrectDeliveryDayName } from '../getCorrectDeliveryDayName'
import { getMealSelectionDeliveryDate } from '../getMealSelectionDeliveryDate'
import momentUtc from '../momentUtc'
import { Moment } from 'moment'

export const getDeliveryMomentsByNextCharges = (
	nextCharges: RechargeCharge[],
	currentMoment: Moment,
	region: string,
	deliveryDayName?: string,
	firstDeliveryDate?: string
): Moment[] => {
	return nextCharges.map(charge => {
		return getMealSelectionDeliveryDate({
			currentDateMoment: currentMoment,
			region,
			nextChargeDateMoment: momentUtc(charge.scheduled_at),
			deliveryDayName: getCorrectDeliveryDayName(deliveryDayName, firstDeliveryDate),
			firstDeliveryDate
		})
	})
}
