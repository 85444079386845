import {
	getLanguageFromLocale,
	getRegionFromLocale
} from '~/helpers/locale'
import { useRouter } from 'next/router'
import nextI18nextConfig from '~/next-i18next.config.js'
import usePersistLocaleCookie from '~/helpers/usePersistLocaleCookie'
import { useCallback } from 'react'
import defaultLocaleByRegion from '~/defaultLocaleByRegion'
import { useRecoilState } from 'recoil'
import { baseMealPlanProductQuery } from '~/store/atoms/checkout/baseMealPlanProductQuery'

type SelectLocaleParams = {
	language?: string;
	location?: string;
	onSuccess?: () => void;
}

export const useLocaleSelector = () => {
	const [, setBaseMealPlanProductQuery] = useRecoilState(baseMealPlanProductQuery)
	const router = useRouter()
	const locale = router.locale || nextI18nextConfig.i18n.defaultLocale
	const selectedLanguage = getLanguageFromLocale(locale)
	const selectedLocation = getRegionFromLocale(locale)
	const { updateLocaleCookie } = usePersistLocaleCookie()
	const selectLocale = useCallback(async ({
		language,
		location,
		onSuccess
	}: SelectLocaleParams) => {
		let newLocale = `${language || selectedLanguage}-${location?.toUpperCase() || selectedLocation.toUpperCase()}`
		if (
			location &&
			!router.locales.includes(newLocale)
		) {
			newLocale = defaultLocaleByRegion[location]
		}
		updateLocaleCookie(newLocale)
		const localeData = await import(`~/assets/jsons/data/${newLocale}.json`)
		setBaseMealPlanProductQuery(localeData.baseMealPlanProductQuery)
		router.push({
			pathname: router.pathname,
			query: router.query
		}, router.asPath, {
			locale: newLocale
		}).then(() => {
			if (onSuccess) onSuccess()
		}).catch(error => {
			console.error(error)
		})
	}, [router, selectedLanguage, selectedLocation, setBaseMealPlanProductQuery, updateLocaleCookie])

	return {
		selectLocale,
		selectedLanguage,
		selectedLocation
	}
}
