import { selector } from 'recoil'
import contentfulClient from '~/adapters/contentfulClient'
import { IReferralFields } from '@shared/types/generated/contentful'
import { selectedLocaleState } from './selectedLocaleState'
import { getLanguageFromLocale } from '~/helpers/locale'

export const referralState = selector({
	key: 'checkout:referralState',
	get: async ({ get }) => {
		const locale = get(selectedLocaleState)
		if (locale === null) throw new Error('locale empty')
		const language = getLanguageFromLocale(locale)
		const referral = (
			await contentfulClient.getEntries<IReferralFields>({
				content_type: 'referral',
				limit: 1,
				locale: language
			})
		).items[0]

		return referral
	}
})
