import { BusinessConfigDelivery } from '@shared/businessConfig'
import getCurrentMoment from '@shared/helpers/getCurrentMoment'
import { DashboardSettings } from '@shared/interfaces/Settings.interface'
import { firebaseTimestampToMomentUtc } from '~/functions/src/shared/helpers/converters/firebaseTimestampToMomentUtc'
import { IGlobalSettingsFields } from '~/functions/src/shared/types/generated/contentful'
import moment from 'moment'

export const handleFilterDeliveryDate = (
	date: Date,
	deliveries: BusinessConfigDelivery[],
	dashboardSettings: DashboardSettings,
	region: string,
	globalSettings: IGlobalSettingsFields,
	postalCode?: string
): boolean => {
	if (!dashboardSettings || !globalSettings) return false
	const dateMoment = moment(date)
	const day = dateMoment.day()
	const delivery = deliveries.find(delivery => delivery.deliveryDay === day)
	if (!delivery) return false
	const postalCodeOnlyNumbers = postalCode?.replace(/\D/g, '')
	if (
		delivery.zipcodesList === 'monday' &&
		!globalSettings.mondayDeliveryZipcodes?.includes(postalCodeOnlyNumbers)
	) {
		return false
	}
	const maxValidMoment = dateMoment.clone().subtract(
		delivery.minDaysBeforeNearestDeliveryDeadline,
		'days'
	).endOf('day')
	if (delivery.maxSelectionHour) {
		maxValidMoment.set('hour', delivery.maxSelectionHour).set('minute', 0)
	}
	// Find date in disabled delivery dates array
	const isDeliveryDateDisabledInSettings = Boolean(
		dashboardSettings.disabledDeliveryDatesByRegion?.[region.toUpperCase()]?.find(
			timestamp => {
				return firebaseTimestampToMomentUtc(timestamp).isSame(dateMoment, 'day')
			}
		)
	)
	/* const isDeliveryDateDisabledByMealsLimit = Boolean(
		dashboardSettings.mealsLimitDisabledDates?.find(
			date => moment(date, businessConfig.mealsLimitDateFormat).isSame(dateMoment, 'day')
		)
	) */
	const isDeliveryDateDisabled = isDeliveryDateDisabledInSettings
	if (isDeliveryDateDisabled) return false
	const currentMoment = getCurrentMoment()
	if (currentMoment.isAfter(maxValidMoment)) return false
	return true
}
