import {
	ChangeEvent,
	KeyboardEvent,
	FC,
	useCallback,
	useState
} from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { Button } from '@chakra-ui/button'
import { Input } from '@chakra-ui/input'
import { useToast } from '@chakra-ui/toast'
import { subscribeToNewsletter } from '~/adapters/subscribeToNewsletter'
import { useAsync } from '~/hooks/useAsync'
import captureCustomEvent from '~/helpers/captureCustomEvent'
import { Heading } from '@chakra-ui/react'

export type SubscribeToNewsletterProps = {
	className?: string
	wrapperClassName?: string
}

export const SubscribeToNewsletter: FC<SubscribeToNewsletterProps> = ({
	className,
	wrapperClassName
}) => {
	const [email, setEmail] = useState('')
	const { t } = useTranslation()
	const toast = useToast()
	const {
		status,
		execute
	} = useAsync({
		asyncFunction: async () => {
			await subscribeToNewsletter(email)
		},
		onSuccess: () => {
			captureCustomEvent('lead', {
				email
			})
			toast({
				title: t('common:subscribeToNewsletter.successTitle'),
				description: t('common:subscribeToNewsletter.successDescription'),
				status: 'success'
			})
		},
		onError: (error) => {
			toast({
				title: t('common:subscribeToNewsletter.alreadySubscribedTitle'),
				description: t('common:subscribeToNewsletter.alreadySubscribedDescription'),
				status: 'success'
			})
		}
	})
	const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value)
	}, [setEmail])
	const onKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter' && execute) execute()
	}, [execute])

	return (
		<div
			className={wrapperClassName}
		>
			<div
				className={classNames(
					className,
					'flex items-center justify-center',
				)}
			>
				<div
					className={classNames(
						'flex flex-col md:flex-row justify-between w-full px-5 md:px-12 bg-secondary',
						'md:rounded-4xl py-16 sm:py-8 items-center space-y-6 md:space-y-0'
					)}
				>
					<div
						className={'flex-1 w-full'}
					>
						<Heading
							className={'text-white text-center md:text-left font-semibold mr-4'}
							lineHeight={'1.1'}
							size={'md'}
						>
							{t('common:subscribeToNewsletter.description')}
						</Heading>
					</div>
					<div
						className={'w-full sm:max-w-lg flex flex-col sm:flex-row items-center space-y-5 sm:space-y-0 sm:space-x-4'}
					>
						<Input
							placeholder={t('common:subscribeToNewsletter.emailPlaceholder')}
							value={email}
							onChange={handleInput}
							onKeyPress={onKeyPress}
							disabled={status === 'success' || status === 'pending'}
							backgroundColor={'white'}
							borderRadius={'xl'}
							border={0}
							size={'lg'}
						/>
						<Button
							aria-label={'Subscribe'}
							colorScheme={'secondaryDark'}
							variant={'solid'}
							fontWeight={'normal'}
							fontFamily={'heading'}
							isDisabled={status === 'success'}
							isLoading={status === 'pending'}
							onClick={execute}
							minWidth={'auto'}
							size={'lg'}
						>
							{t('common:subscribeToNewsletter.button.text')}
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}
