import { UserRecord } from 'firebase-admin/auth'
import {
	createContext,
	FC
} from 'react'
import { useWithAuthentication } from '~/hooks/useWithAuthentication'
import { customer_customer } from '@shared/types/shopify'
import { UseAsyncStatus } from '~/hooks/useAsyncWithParams'

type UserContextProviderProps = {
	props: Record<any, any>
}

type UserContextValue = {
	user: UserRecord | null
	setUser: (user: UserRecord | null) => void
	featureFlags: Record<string, boolean | string>
	customerData: customer_customer | null
	setCustomerData: (customerData: customer_customer | null) => void
	customerDataStatus: UseAsyncStatus
	setCustomerDataStatus: (customerDataStatus: UseAsyncStatus) => void
	customerAccessToken: string | null
	setCustomerAccessToken: (customerAccessToken: string | null) => void
	customerAccessTokenLoading: boolean
	setCustomerAccessTokenLoading: (customerAccessTokenLoading: boolean) => void
}

export const UserContext = createContext<UserContextValue>({
	user: null,
	setUser: () => {},
	featureFlags: {
	},
	customerData: null,
	setCustomerData: () => {},
	customerDataStatus: 'idle',
	setCustomerDataStatus: () => {},
	customerAccessToken: null,
	setCustomerAccessToken: () => {},
	customerAccessTokenLoading: false,
	setCustomerAccessTokenLoading: () => {}
})

export const UserContextProvider: FC<UserContextProviderProps> = ({
	children,
	props
}) => {
	const { userContextValue } = useWithAuthentication(props)
	return (
		<UserContext.Provider
			value={userContextValue}
		>

			{children}
		</UserContext.Provider>
	)
}
