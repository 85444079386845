import {
	FC,
	useMemo
} from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import TagIcon from 'assets/icons/tag.svg'
import { GetDiscountCodeByCodeResponse } from '@shared/types/GetDiscountCodeByCodeResponse'

type DiscountAppliedBannerProps = {
	discountData: GetDiscountCodeByCodeResponse
	discountCode: string
}

export const DiscountAppliedBanner: FC<DiscountAppliedBannerProps> = ({
	discountData,
	discountCode
}) => {
	const { t } = useTranslation()
	const discountValueText = useMemo(() => {
		let valueText: string
		if (discountData.shopifyDiscount.customerGets.value.percentage) {
			valueText = `${discountData.shopifyDiscount.customerGets.value.percentage * 100}%`
		} else {
			valueText = `${discountData.shopifyDiscount.customerGets.value.amount.amount}€`
		}
		return valueText
	}, [
		discountData
	])
	const recurringDiscountValueText = useMemo(() => {
		let valueText: string
		if (!discountData.rechargeDiscount) {
			return null
		}
		const value = Number(discountData.rechargeDiscount.value)
		if (discountData.rechargeDiscount.value_type === 'percentage') {
			valueText = `${value}%`
		} else {
			valueText = `${value}€`
		}
		return valueText
	}, [
		discountData
	])
	const discountBannerText = useMemo(() => {
		const rechargeRedemptions = discountData.rechargeDiscount?.usage_limits.max_subsequent_redemptions
		const shopifyRedemptions = discountData.shopifyDiscount.recurringCycleLimit
		if (
			rechargeRedemptions > 1 &&
			// Recharge redemptions are always 1 less than Shopify redemptions
			// Because the first one is used to create the subscription
			shopifyRedemptions !== rechargeRedemptions + 1
		) {
			return t('checkout:discountBannerTextRecurring', {
				discountValueText,
				recurringDiscountValueText,
				recurringCycleLimit: rechargeRedemptions
			})
		}
		return t(discountData.shopifyDiscount.recurringCycleLimit > 1 ? 'checkout:discountBannerText_plural' : 'checkout:discountBannerText', {
			discountValueText,
			recurringCycleLimit: shopifyRedemptions
		})
	}, [discountData, discountValueText, recurringDiscountValueText, t])

	return (
		<div
			className={classNames(
				'bg-secondary flex items-center justify-center',
				'font-semibold text-center pl-1.5 xl:pl-4 pr-1.5 py-1.5 z-30',
				'fixed top-0 left-0 h-12 w-full sm:w-auto sm:h-auto sm:rounded-full sm:top-auto sm:bottom-6 sm:left-6 z-30'
			)}
		>
			<p
				className={'text-white text-sm mr-3 sm:hidden xl:block'}
			>
				{discountBannerText}
			</p>
			<div
				className={classNames(
					'flex items-center justify-center text-sm',
					'bg-white rounded-full h-8 text-palette-darkGreen px-3'
				)}
			>
				<TagIcon
					width={17}
					height={17}
					className={'mr-1'}
				/>
				{discountCode}
			</div>
		</div>
	)
}
