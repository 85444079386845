import { signOut } from '@firebase/auth'
import { useRouter } from 'next/router'
import SettingsIcon from '~/assets/icons/settings.svg'
import HomeIcon from '~/assets/icons/home.svg'
import LineChartIcon from '~/assets/icons/bar-chart-2.svg'
import FilePlusIcon from '~/assets/icons/file-plus.svg'
import LogoutIcon from '~/assets/icons/log-out.svg'
import UsersIcon from '~/assets/icons/users.svg'
import BoxIcon from '~/assets/icons/box.svg'
import BriefcaseIcon from '~/assets/icons/briefcase.svg'
import { auth } from '~/adapters/firebase'
import { useToast } from '@chakra-ui/toast'
import classNames from 'classnames'
import Link from 'next/link'
import { useCallback } from 'react'

const MenuButton = ({
	id,
	href,
	icon,
	danger,
	onClick,
	children
}: {
	href?: string;
	icon: React.ReactNode;
	danger?: boolean;
	onClick?: () => void;
	children: React.ReactNode;
} & React.HTMLAttributes<HTMLAnchorElement>) => {
	const router = useRouter()
	const isActive = router.pathname === href
	if (!href) return <></>
	return (
		<Link
			id={id}
			href={href}
			onClick={onClick}
			className={classNames(
				'flex items-center px-4 py-2 text-sm font-medium rounded-md duration-100',
				'focus:opacity-60',
				isActive && '!text-white bg-blue-500',
				(danger && !isActive) && 'text-red-600 bg-red-50 hover:bg-red-100 hover:text-red-700',
				(!danger && !isActive) && 'text-gray-600 hover:bg-neutral-100 hover:text-gray-900'
			)}
		>
			{icon}
			<span
				className={'ml-2'}
			>
				{children}
			</span>
		</Link>
	)
}
const LeftMenu = () => {
	const toast = useToast()
	const router = useRouter()
	const handleSignOut = useCallback(async (): Promise<void> => {
		try {
			await signOut(auth)
			await router.replace('/')
			toast({
				title: 'Signed out successfully',
				status: 'success'
			})
		} catch (error) {
			toast({
				title: 'Something went wrong',
				status: 'error'
			})
		}
	}, [router, toast])
	const renderIconComponent = useCallback((Icon: any) => {
		return (
			<Icon
				width={20}
				height={20}
			/>
		)
	}, [])

	return (
		<div
			className={'h-full relative space-y-2 px-3 pt-3 shrink-0'}
			style={{
				width: 230
			}}
		>
			<MenuButton
				href={'/dashboard'}
				icon={renderIconComponent(HomeIcon)}
			>
				{'Home'}
			</MenuButton>
			<MenuButton
				id={'upcoming-orders-link'}
				href={'/dashboard/upcoming-orders'}
				icon={renderIconComponent(BoxIcon)}
			>
				{'Upcoming Orders'}
			</MenuButton>
			<MenuButton
				id={'analytics-link'}
				href={'/dashboard/analytics'}
				icon={renderIconComponent(LineChartIcon)}
			>
				{'Analytics'}
			</MenuButton>
			<MenuButton
				href={'/dashboard/employees'}
				icon={renderIconComponent(BriefcaseIcon)}
			>
				{'Employees'}
			</MenuButton>
			<MenuButton
				id={'exports-link'}
				href={'/dashboard/exports'}
				icon={renderIconComponent(FilePlusIcon)}
			>
				{'Exports'}
			</MenuButton>
			<MenuButton
				id={'customers-link'}
				href={'/dashboard/customers'}
				icon={renderIconComponent(UsersIcon)}
			>
				{'Customers'}
			</MenuButton>
			<MenuButton
				id={'settings-link'}
				href={'/dashboard/settings'}
				icon={renderIconComponent(SettingsIcon)}
			>
				{'Settings'}
			</MenuButton>
			<div
				className={'py-3'}
			>
				<div
					className={'w-full h-px bg-neutral-100'}
				/>
			</div>
			<MenuButton
				danger={true}
				onClick={handleSignOut}
				icon={renderIconComponent(LogoutIcon)}
			>
				{'Log Out'}
			</MenuButton>
		</div>
	)
}

export default LeftMenu
