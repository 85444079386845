import { useRouter } from 'next/router'
import {
	useCallback,
	useEffect,
	useMemo
} from 'react'
import businessConfig from '~/functions/src/shared/businessConfig'
import getCurrentMoment from '~/functions/src/shared/helpers/getCurrentMoment'
import {
	getLanguageFromLocale,
	getRegionFromLocale
} from '~/helpers/locale'
import moment from 'moment-timezone'

export const useTimezone = () => {
	const { locale } = useRouter()
	const region = useMemo(
		() => getRegionFromLocale(locale).toUpperCase(),
		[locale]
	)
	const language = useMemo(
		() => getLanguageFromLocale(locale),
		[locale]
	)
	const timezone = useMemo(
		() => businessConfig.timezoneByRegion[region],
		[region]
	)
	const getTimezoneCurrentMoment = useCallback(
		() => getCurrentMoment(timezone),
		[timezone]
	)

	useEffect(() => {
		moment.tz.setDefault(timezone)
	}, [timezone])

	useEffect(() => {
		moment.locale(language)
	}, [language])

	return {
		timezone,
		getTimezoneCurrentMoment,
		tzMoment: moment
	}
}
