import gql from 'graphql-tag'

export const CustomerUpdateMutation = gql`
	mutation customerUpdate (
		$customerAccessToken: String!,
		$customer: CustomerUpdateInput!
	) {
		customerUpdate (
			customerAccessToken: $customerAccessToken,
			customer: $customer
		) {
			customer {
				id
			}
		}
	}
`
