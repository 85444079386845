import { selector } from 'recoil'
import { SubscriptionWithAddress } from '@shared/interfaces/SubscriptionWithAddress.interface'
import { userSubscriptionsWithAddressesState } from './userSubscriptionsWithAddressesState'

export const userActiveSubscriptionsWithAddressesState = selector<SubscriptionWithAddress[]>({
	key: 'subscriptions:userActiveSubscriptions',
	get: ({ get }) => {
		const userSubscriptionsWithAddresses = get(userSubscriptionsWithAddressesState) ?? []
		return userSubscriptionsWithAddresses.filter(e => e.subscription.status === 'active')
	}
})
