import { Delivery } from '@rechargeapps/storefront-client'
import businessConfig from '@shared/businessConfig'
import { DayNames } from '@shared/helpers/converters/convertDayNameToNumber'

export const getDeliveryDayNameFromDelivery = (
	delivery: Delivery,
	subscriptionId: number
): DayNames => {
	const order = delivery.orders.find(
		e => e.line_items.find(item => item.subscription_id === subscriptionId)
	)
	const lineItem = order?.line_items.find(e => e.subscription_id === subscriptionId)
	return lineItem?.properties.find(
		attribute => attribute.name === businessConfig.deliveryDayAttributeKey
	)?.value as DayNames
}
