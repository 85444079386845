import {
	connectFirestoreEmulator,
	initializeFirestore,
	getFirestore,
	Firestore
} from '@firebase/firestore'
import {
	connectFunctionsEmulator,
	getFunctions
} from '@firebase/functions'
import {
	connectAuthEmulator,
	getAuth
} from '@firebase/auth'
import {
	FirebaseApp,
	getApp,
	initializeApp
} from 'firebase/app'
import config from '~/config'
import {
	connectStorageEmulator,
	getStorage
} from '@firebase/storage'

let app: FirebaseApp
let db: Firestore
try {
	app = getApp()
} catch {
	app = initializeApp(config.firebase)
}

try {
	db = initializeFirestore(app, {
		experimentalForceLongPolling: process.env.EXPERIMENTAL_FORCE_LONG_POLLING === 'enabled' ||
		process.env.NEXT_PUBLIC_EXPERIMENTAL_FORCE_LONG_POLLING === 'enabled'
	})
} catch {
	db = getFirestore()
}

const functions = getFunctions()
const auth = getAuth()
const storage = getStorage()
if (
	process.env.NODE_ENV === 'development' &&
	process.env.NEXT_PUBLIC_FIREBASE_MODE !== 'production'
) {
	connectFirestoreEmulator(db, 'localhost', 8080)
	connectFunctionsEmulator(functions, 'localhost', 5001)
	connectAuthEmulator(auth, 'http://localhost:9099')
	connectStorageEmulator(storage, 'localhost', 9199)
}

export {
	db, functions, auth, storage
}
