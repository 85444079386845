import { Transition } from '@headlessui/react'
import React, { FC } from 'react'
import classNames from 'classnames'

type NavbarPopoverProps = {
	isOpen: boolean;
	onClose: () => void;
}

export const NavbarPopover: FC<NavbarPopoverProps> = ({
	isOpen,
	onClose,
	children
}) => {
	return (
		<Transition
			show={isOpen}
		>
			<Transition.Child
				as={React.Fragment}
				enter={'transition ease-in-out duration-150'}
				enterFrom={'transform opacity-0 scale-95'}
				enterTo={'transform opacity-100 scale-100'}
				leave={'transition ease-out duration-100'}
				leaveFrom={'transform opacity-100 scale-100'}
				leaveTo={'transform opacity-0 scale-95'}
			>
				<div
					className={classNames(
						'absolute right-0 z-dropdown w-60 mt-2 origin-top-left rounded-3xl outline-none',
						'bg-white border border-neutral-100 px-2 py-3 z-30'
					)}
				>
					<div
						role={'group'}
					>
						{ children }
					</div>
				</div>
			</Transition.Child>
		</Transition>
	)
}
