const config = {
	firebase: {
		apiKey: 'AIzaSyBxOiQqTQ6xhrLbTeCLWMSkHELfJFg94y4',
		authDomain: 'auth.freshgreens.es',
		projectId: 'vfc-firebase',
		storageBucket: 'vfc-firebase.appspot.com',
		messagingSenderId: '944550230886',
		appId: '1:944550230886:web:6ae0cf666a0ac4cdbaa6a7'
	},
	firebaseClientEmail: 'firebase-adminsdk-o38kg@vfc-firebase.iam.gserviceaccount.com',
	getMultipassTokenUrl: process.env.NODE_ENV === 'development' ?
		'http://127.0.0.1:5001/vfc-firebase/us-central1/shopify-getMultipassToken' :
		'https://us-central1-vfc-firebase.cloudfunctions.net/shopify-getMultipassToken',
	crossDomainLinkerDomains: ['veganfood-club.myshopify.com'],
	gaMeasurementId: 'UA-162784632-1',
	carouselBreakpoints: {
		xl: {
			breakpoint: {
				max: 4000,
				min: 1280
			},
			items: 5,
			slidesToSlide: 5
		},
		lg: {
			breakpoint: {
				max: 1280,
				min: 1024
			},
			items: 4,
			slidesToSlide: 4
		},
		md: {
			breakpoint: {
				max: 1024,
				min: 464
			},
			items: 3,
			slidesToSlide: 3
		},
		sm: {
			breakpoint: {
				max: 464,
				min: 0
			},
			items: 1,
			slidesToSlide: 1
		}
	},
	displayedNumberOfOrdersOptions: [10, 25, 50],
	exportsListLimit: 10,
	deliveryCountries: [
		{
			label: 'Spain',
			value: 'ES'
		},
		{
			label: 'Portugal',
			value: 'PT'
		},
		{
			label: 'Andorra',
			value: 'AD'
		}
	],
	routes: {
		'/dashboard/upcoming-orders': {
			title: 'Upcoming Orders',
			description: 'List of upcoming orders grouped by delivery days'
		},
		'/dashboard/employees': {
			title: 'Employees',
			description: 'Give other users access to the dashboard'
		},
		'/dashboard/exports': {
			title: 'Exports',
			description: 'All spreadsheets exported on Upcoming Orders page'
		}
	},
	numberOfFutureDeliveriesDisplayedInCustomerPage: 3,
	checkoutProgressBarRoutes: [
		'/checkout/plans',
		'/checkout/register',
		'/checkout/address',
		'/checkout/thank_you'
	],
	defaultFeatureFlags: {
		deliverySkipsEnabled: false,
		loyaltyDialogEnabled: false,
		mealsHistoryPageEnabled: false,
		upsellsEnabled: false,
		changeSubscriptionTypeEnabled: false,
		nutritionalInfoEnabled: false
	},
	languageNamespaces: [
		'auth',
		'checkout',
		'common',
		'customer',
		'errorMessages',
		'loyalty',
		'notifications',
		'plans',
		'products',
		'summaryPage',
		'thankYouPage'
	],
	blogPageSize: 8,
	siteTitle: '| freshgreens.es',
	hardcodedFeatureFlags: {
		useAddressPage: true
	}
}

export default config
