import { selector } from 'recoil'
import { SubscriptionWithAddress } from '@shared/interfaces/SubscriptionWithAddress.interface'
import { subscriptionsRegionsState } from './subscriptionsRegionsState'
import { userSubscriptionsWithAddressesState } from './userSubscriptionsWithAddressesState'

export const userSubscriptionsWithAddressesByRegionState = selector<{
	[region: string]: SubscriptionWithAddress[]
}>({
	key: 'subscriptions:userSubscriptionsWithAddressesByRegion',
	get: ({ get }) => {
		const regions = get(subscriptionsRegionsState)
		const subscriptionsWithAddressesByRegion: {
			[region: string]: SubscriptionWithAddress[]
		} = {
		}
		const userSubscriptionsWithAddresses = get(userSubscriptionsWithAddressesState) ?? []
		for (const region of regions) {
			subscriptionsWithAddressesByRegion[region.toLocaleLowerCase()] = userSubscriptionsWithAddresses.filter(e => e.address.country_code === region)
		}
		return subscriptionsWithAddressesByRegion
	}
})
