import { BusinessConfigDelivery } from '@shared/businessConfig'
import { RechargeSubscription } from '@shared/interfaces/RechargeSubscription.interface'
import { Moment } from 'moment-timezone'
import { MealSelection } from '.'
import { getDeliveryMomentsByDeliverySchedule } from './getDeliveryMomentsByDeliverySchedule'
import { getMealSelectionByMoment } from './getMealSelectionByMoment'
import { DayNames } from '@shared/helpers/converters/convertDayNameToNumber'
import { RechargeCharge } from '@shared/interfaces/RechargeCharge.interface'
import { Delivery } from '@rechargeapps/storefront-client'

export const getMealSelectionsByDeliverySchedule = (
	deliverySchedule: Delivery[],
	subscription: RechargeSubscription,
	currentDateMoment: Moment,
	region: string,
	deliveries: BusinessConfigDelivery[],
	nextCharges: RechargeCharge[],
	deliveryDayName?: DayNames
): MealSelection[] => {
	const deliveryMoments = getDeliveryMomentsByDeliverySchedule(
		deliverySchedule,
		subscription,
		currentDateMoment,
		region,
		deliveryDayName
	)
	const mealSelections: MealSelection[] = []
	deliveryMoments.forEach((deliveryMoment, index) => {
		const deliveryScheduleDelivery = deliverySchedule[index]
		const orders = deliveryScheduleDelivery?.orders || []
		const charge = nextCharges.find(charge => {
			return orders.find(order => order.charge_id === charge.id)
		})
		const status = charge?.status === 'success' ? 'paid' : 'unpaid'
		const mealSelection = getMealSelectionByMoment(
			deliveryMoment,
			deliveries,
			currentDateMoment,
			status,
			charge
		)
		if (!mealSelection) return
		mealSelections.push(mealSelection)
	})
	return mealSelections
}
