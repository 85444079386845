import { useToast } from '@chakra-ui/toast'
import { useState } from 'react'
import { redeemCouponCode } from '~/adapters/loyalty/redeemCouponCode'

export const useHandleRedeemCouponCode = () => {
	const toast = useToast()
	const [loadingSubscriptionId, setLoadingSubscriptionId] = useState<number | null>(null)
	const handleRedeemCouponCode = async (
		loyaltyActionCouponId: string,
		subscriptionId: number,
		onSuccess: (couponCode: string) => Promise<void>
	) => {
		try {
			setLoadingSubscriptionId(subscriptionId)
			const loyaltyChange = await redeemCouponCode(loyaltyActionCouponId)
			await onSuccess(loyaltyChange.couponCode)
			toast({
				status: 'success',
				title: 'notifications:loyaltyCouponRedeemSuccessTitle',
				description: 'notifications:loyaltyCouponRedeemSuccessDescription'
			})
		} catch (error) {
			toast({
				status: 'error',
				title: 'notifications:genericErrorTitle',
				description: 'notifications:genericErrorDescription'
			})
		} finally {
			setLoadingSubscriptionId(null)
		}
	}

	return {
		handleRedeemCouponCode,
		loadingSubscriptionId
	}
}
