import { Button } from '@chakra-ui/button'
import React, { useCallback } from 'react'
import { useAuthState } from '~/hooks/useAuthState'
import MenuIcon from 'assets/icons/menu.svg'
import { useLogOut } from '~/hooks/useLogOut'
import { IButton } from '@shared/types/generated/contentful'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { NavbarPopover } from 'components/Navbar/NavbarPopover'
import Link from 'next/link'
import {
	useRecoilState,
	useRecoilValue
} from 'recoil'
import { loyaltyPointsState } from '~/store/atoms/customer/loyaltyPointsState'
import { isLoyaltyDialogOpenState } from '~/store/global'
import LoadingIcon from '../LoadingIcon'

interface NavbarMobileMenuProps {
	buttons: IButton[];
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	loyaltyDialogEnabled: boolean;
}

const NavbarMobileMenu = ({
	buttons,
	isOpen,
	loyaltyDialogEnabled,
	setIsOpen
}: NavbarMobileMenuProps): JSX.Element => {
	const { t } = useTranslation()
	const router = useRouter()
	const [user, loading] = useAuthState()
	const loyaltyPoints = useRecoilValue(loyaltyPointsState)
	const {
		logOut,
		isLoading
	} = useLogOut()
	const [, setIsLoyaltyDialogOpen] = useRecoilState(isLoyaltyDialogOpenState)
	const handleOpen = useCallback(() => {
		setIsOpen(true)
	}, [setIsOpen])
	const handleClose = useCallback(() => {
		setIsOpen(false)
	}, [setIsOpen])
	const handleOpenLoyaltyDialog = useCallback(() => {
		setIsLoyaltyDialogOpen(true)
		handleClose()
	}, [setIsLoyaltyDialogOpen, handleClose])
	const handleLogOut = useCallback(() => {
		logOut({
			close: handleClose
		})
	}, [logOut, handleClose])
	const getLinkHref = useCallback((button: IButton) => ({
		pathname: button.fields.href,
		query: router.query,
		hash: button.fields.hash
	}), [router.query])

	return (
		<div
			className={'relative flex items-center'}
		>
			<button
				aria-label={'Menu'}
				onClick={isOpen ? handleClose : handleOpen}
				className={'bg-secondary rounded-full p-2 text-white hover:opacity-75 active:opacity-50 duration-100'}
			>
				{loading ? (
					<LoadingIcon
						className={'w-5 h-5'}
					/>
				) : (
					<MenuIcon
						width={25}
						height={25}
					/>
				)}
			</button>
			<NavbarPopover
				isOpen={isOpen}
				onClose={handleClose}
			>
				<p
					className={'mx-3 mt-1 mb-2 font-semibold'}
				>
					{ t('common:menu') }
				</p>
				{buttons.map(button => (
					<Link
						href={getLinkHref(button)}
						key={button.fields.href + '-mobile'}
						onClick={handleClose}
					>
						<Button
							variant={'ghost'}
							colorScheme={'secondary'}
							className={'w-full font-normal'}
							justifyContent={'flex-start'}
							size={'sm'}
						>
							{ button.fields.text }
						</Button>
					</Link>
				))}
				<p
					className={'mx-3 my-2 font-semibold'}
				>
					{ t('common:profile') }
				</p>
				{user ? (
					<>
						<Link
							href={'/customer'}
							onClick={handleClose}
						>
							<Button
								variant={'ghost'}
								className={'w-full font-normal'}
								justifyContent={'flex-start'}
								size={'sm'}
							>
								{ t('common:yourSubscriptions') }
							</Button>
						</Link>
						{loyaltyDialogEnabled && (
							<Button
								variant={'ghost'}
								className={'w-full font-normal'}
								justifyContent={'flex-start'}
								onClick={handleOpenLoyaltyDialog}
								size={'sm'}
							>
								{ t('common:referAFriendButtonText') }{' '}
								{'('}{t('loyalty:points', {
									points: loyaltyPoints
								})}{')'}
							</Button>
						)}
						<Button
							variant={'ghost'}
							colorScheme={'danger'}
							className={'w-full font-normal'}
							justifyContent={'flex-start'}
							onClick={handleLogOut}
							isLoading={isLoading}
							size={'sm'}
						>
							{ t('common:logOut') }
						</Button>
					</>
				) : (
					<>
						<Link
							href={'/customer/sign-in'}
							onClick={handleClose}
						>
							<Button
								variant={'ghost'}
								className={'w-full font-normal'}
								justifyContent={'flex-start'}
								size={'sm'}
							>
								{ t('common:signIn') }
							</Button>
						</Link>
						<Link
							href={'/customer/sign-up'}
							onClick={handleClose}
						>
							<Button
								variant={'ghost'}
								className={'w-full font-normal'}
								justifyContent={'flex-start'}
								size={'sm'}
							>
								{ t('common:signUp') }
							</Button>
						</Link>
					</>
				)}
			</NavbarPopover>
		</div>
	)
}

export default NavbarMobileMenu
