import { useRouter } from 'next/router'
import NavbarDesktopMenu from 'components/Navbar/NavbarDesktopMenu'
import NavbarMobileMenu from 'components/Navbar/NavbarMobileMenu'
import DesktopLogo from '~/public/logo.svg'
import Link from 'next/link'
import NavbarUserMenu from 'components/Navbar/NavbarUserMenu'
import { IButton } from '@shared/types/generated/contentful'
import classNames from 'classnames'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { useRecoilValue } from 'recoil'
import { userRoleState } from '~/store/atoms/dashboard/userRoleState'
import { useFeatureFlags } from '~/hooks/useFeatureFlags'
import { useDiscountCode } from '~/hooks/useDiscountCode'

const NavbarComponent = ({
	hideNavigation = false,
	buttons,
	useSmallLogo = false,
	className
}: {
	buttons: IButton[];
	hideNavigation?: boolean;
	useSmallLogo?: boolean;
	className?: string
}): JSX.Element => {
	const userRole = useRecoilValue(userRoleState)
	const { getFeatureFlag } = useFeatureFlags()
	const [
		isNavbarVisible, setIsNavbarVisible
	] = useState(true)
	const  [
		onScrollPending, setOnScrollPending
	] = useState(false)
	const [
		isMobileMenuOpen, setIsMobileMenuOpen
	] = useState(false)
	const [
		isUserMenuOpen, setIsUserMenuOpen
	] = useState(false)
	const {
		query,
		pathname
	} = useRouter()
	const isCheckout = useMemo(() => pathname.includes('/checkout'), [pathname])
	const { discountCode: activeDiscountCode } = useDiscountCode()
	const isDiscountAppliedBannerVisible = useMemo(() => {
		if (pathname.includes('/customer')) return false
		return true
	}, [pathname])
	const onScroll = useCallback(() => {
		const scrollThreshold = 5
		const maxScrollYAlwaysVisible = 100
		if (onScrollPending || isMobileMenuOpen || isUserMenuOpen) return
		setOnScrollPending(true)
		const startScrollY = window.scrollY
		if (window.scrollY <= maxScrollYAlwaysVisible) {
			setIsNavbarVisible(true)
			setOnScrollPending(false)
			return
		}
		setTimeout(() => {
			if (startScrollY >= (window.scrollY + scrollThreshold)) {
				setIsNavbarVisible(true)
			} else {
				setIsNavbarVisible(false)
			}
			setOnScrollPending(false)
		}, 400)
	}, [isMobileMenuOpen, onScrollPending, isUserMenuOpen])
	const loyaltyDialogEnabled = useMemo(() => {
		return Boolean(
			getFeatureFlag('loyaltyDialogEnabled') ||
			process.env.NODE_ENV === 'development' ||
			userRole ===	'admin'
		)
	}, [getFeatureFlag, userRole])

	useEffect(() => {
		if (typeof window === 'undefined') return
		window.addEventListener('scroll', onScroll)
		return () => window.removeEventListener('scroll', onScroll)
	}, [onScroll, onScrollPending])

	const homepageHref = useMemo(() => ({
		pathname: '/',
		query
	}), [query])
	if (isCheckout) return null

	return (
		<div
			className={classNames(
				'w-full flex items-center justify-center',
				activeDiscountCode && isDiscountAppliedBannerVisible && 'pt-12 sm:pt-0'
			)}
		>
			<header
				className={classNames(
					'xl:p-3 z-20 fixed top-0 theContainer w-full',
					'duration-200',
					activeDiscountCode && isDiscountAppliedBannerVisible && 'pt-12 sm:pt-0',
					isCheckout ? 'bg-transparent' : 'bg-white sm:bg-transparent',
					className
				)}
				style={{
					transform: isNavbarVisible ? 'translateY(0)' : 'translateY(-200%)'
				}}
			>
				<div
					className={classNames(
						'flex items-center justify-end relative py-6 rounded-full w-full',
						'sm:p-3 sm:mt-10 xl:justify-between',
						!isCheckout && 'bg-white sm:border border-gray-100',
					)}
				>
					{!isCheckout && (
						<NavbarDesktopMenu
							buttons={buttons.slice(0, 3)}
						/>
					)}
					<Link
						href={homepageHref}
						className={classNames(
							'cursor-pointer z-20 absolute left-1/2 -translate-x-1/2 scale-50',
							useSmallLogo ? 'sm:scale-50':'sm:scale-95',
							isCheckout && 'mt-10',
						)}
						aria-label={'Fresh Greens'}
					>
						<DesktopLogo />
					</Link>
					{!isCheckout && (
						<div
							className={'flex items-center space-x-2'}
						>
							<NavbarDesktopMenu
								buttons={buttons.slice(3)}
							/>
							<NavbarUserMenu
								className={'hidden xl:flex items-center justify-center'}
								isOpen={isUserMenuOpen}
								setIsOpen={setIsUserMenuOpen}
								loyaltyDialogEnabled={loyaltyDialogEnabled}
							/>
						</div>
					)}
					{!isCheckout && (
						<div
							className={'block xl:hidden'}
						>
							<NavbarMobileMenu
								buttons={buttons}
								isOpen={isMobileMenuOpen}
								setIsOpen={setIsMobileMenuOpen}
								loyaltyDialogEnabled={loyaltyDialogEnabled}
							/>
						</div>
					)}
				</div>
			</header>
		</div>
	)
}

export default NavbarComponent
