import { atom } from 'recoil'
import config from '~/config'

type FeatureFlagsType = Record<string, string | boolean>

export const featureFlagsState = atom<FeatureFlagsType>({
	key: 'featureFlags',
	default: config.defaultFeatureFlags
})

export const featureFlagsLoadedState = atom<boolean>({
	key: 'featureFlagsLoaded',
	default: false
})
