import React, {
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import posthog from 'posthog-js'
import {
	featureFlagsLoadedState,
	featureFlagsState
} from '~/store/atoms/featureFlags'
import {
	useRecoilState,
	useRecoilValueLoadable
} from 'recoil'
import { isLoyaltyDialogOpenState } from '~/store/global'
import { onSnapshot } from '@firebase/firestore'
import { db } from '~/adapters/firebase'
import { useRouter } from 'next/router'
import { useSubscriptions } from '~/hooks/useSubscriptions'
import {
	collection,
	CollectionReference,
	Unsubscribe
} from 'firebase/firestore'
import { useAuthState } from '~/hooks/useAuthState'
import { deliverySkipsBySubscriptionIdState } from '~/store/atoms/customer/deliverySkipsBySubscriptionIdState'
import { DeliverySkip } from '~/functions/src/shared/interfaces/DeliverySkip.interface'
import { useDiscountCode } from '~/hooks/useDiscountCode'
import * as Sentry from '@sentry/nextjs'
import { DiscountAppliedBanner } from 'components/DiscountAppliedBanner'
import { referralState } from '~/store/atoms/checkout/referralState'
import { ReferralPopup } from 'components/ReferralPopup'
import { LoyaltyDialog } from 'components/LoyaltyDialog'
import { loyaltyChangesState } from '~/store/atoms/customer/loyaltyChangesState'

/*
 * Used to provide global state to the entire app, client-side.
 * Contains all methods that cannot be used server-side in App.tsx
 */
const GlobalProvider: FC = ({ children }) => {
	const router = useRouter()
	const [user] = useAuthState()
	const {
		activeDiscountData,
		addDiscountCode,
		setDiscountCodeInput,
		discountCode: activeDiscountCode,
		addDiscountCodeStatus
	} = useDiscountCode()
	const { subscriptionsWithAddresses } = useSubscriptions()
	const [, setFeatureFlagsLoaded] = useRecoilState(featureFlagsLoadedState)
	const [, setFeatureFlags] = useRecoilState(featureFlagsState)
	const [, setDeliverySkipsBySubscriptionId] = useRecoilState(
		deliverySkipsBySubscriptionIdState
	)
	const [loyaltyChanges] = useRecoilState(loyaltyChangesState)
	const [defaultLoyaltyDialogIndex, setDefaultLoyaltyDialogIndex] = useState(0)
	const [
		isLoyaltyDialogOpen, setIsLoyaltyDialogOpen
	] = useRecoilState(isLoyaltyDialogOpenState)
	const handleCloseLoyaltyDialog = useCallback((updateQueryParam: boolean = true) => {
		setIsLoyaltyDialogOpen(false)
		if (updateQueryParam) {
			router.replace({
				pathname: router.pathname,
				query: {
					...router.query,
					loyaltyDialogOpen: false
				}
			}, undefined, {
				shallow: true
			})
		}
	}, [router, setIsLoyaltyDialogOpen])
	const referral = useRecoilValueLoadable(referralState)
	const popupReferral = useMemo(
		() => router.query.referralPopup as string,
		[router.query.referralPopup]
	)
	const popupReferralLink = useMemo(() => {
		if (typeof window === 'undefined') return null
		if (referral.state !== 'hasValue') return null
		if (!popupReferral) return null
		return window.location.origin + referral.contents.fields?.redirectUrl + '?referral=' + popupReferral
	}, [referral.state, referral.contents.fields?.redirectUrl, popupReferral])
	const isDiscountAppliedBannerVisible = useMemo(() => {
		if (router.pathname.includes('/customer')) return false
		return true
	}, [router.pathname])

	useEffect(() => {
		if (!router.query.discount) return
		addDiscountCode({
			discountCode: router.query.discount as string,
			showNotification: false
		})
	}, [addDiscountCode, router.query.discount])

	useEffect(() => {
		let unsubscribes: Unsubscribe[] = []
		if (!user) {
			return
		}
		if (!Array.isArray(subscriptionsWithAddresses)) {
			return
		}
		subscriptionsWithAddresses.forEach(({ subscription }) => {
			const collectionRef = collection(
				db,
				`users/${user.email}/subscriptions/${subscription.id}/deliverySkips`
			) as CollectionReference<DeliverySkip>

			unsubscribes.push(
				onSnapshot(collectionRef, (snapshot) => {
					const deliverySkips = snapshot.docs.map(doc => ({
						...doc.data()
					}))

					setDeliverySkipsBySubscriptionId(prev => ({
						...prev,
						[subscription.id]: deliverySkips
					}))
				})
			)
		})
		return () => {
			unsubscribes.forEach(unsubscribe => unsubscribe())
		}
	}, [subscriptionsWithAddresses, setDeliverySkipsBySubscriptionId, user])

	useEffect(() => {
		posthog.onFeatureFlags((_flags, flagsWithValues) => {
			setFeatureFlags(flagsWithValues)
			setFeatureFlagsLoaded(true)
		})
	}, [setFeatureFlags, setFeatureFlagsLoaded])

	useEffect(() => {
		const discountCode = router.query.discount as string | undefined
		if (addDiscountCodeStatus !== 'idle') return
		if (!discountCode) return
		if (
			activeDiscountCode &&
			activeDiscountCode.toUpperCase() === discountCode.toUpperCase()
		) return
		setDiscountCodeInput(discountCode)
		addDiscountCode({
			discountCode
		}).catch(error => {
			console.error(error)
			Sentry.captureException(error)
		})
	}, [activeDiscountCode, addDiscountCode, addDiscountCodeStatus, router.query.discount, setDiscountCodeInput])
	useEffect(() => {
		if (!user) return
		if (router.query.loyaltyDialogOpen === 'true') {
			setDefaultLoyaltyDialogIndex(3)
			setIsLoyaltyDialogOpen(true)
		}
	}, [router.query.loyaltyDialogOpen, setIsLoyaltyDialogOpen, user])
	return (
		<>
			{
				referral.state === 'hasValue' && (
					<LoyaltyDialog
						open={isLoyaltyDialogOpen}
						onClose={handleCloseLoyaltyDialog}
						loyaltyChanges={loyaltyChanges}
						referral={referral.contents.fields}
						defaultIndex={defaultLoyaltyDialogIndex}
					/>
				)
			}
			{popupReferralLink && (
				<ReferralPopup
					link={popupReferralLink}
				/>
			)}
			{activeDiscountData && activeDiscountCode && isDiscountAppliedBannerVisible && (
				<DiscountAppliedBanner
					discountCode={activeDiscountCode}
					discountData={activeDiscountData}
				/>
			)}
			{ children }
		</>
	)
}

export default GlobalProvider
