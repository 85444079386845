import {
	FC,
	Fragment,
	useCallback,
	useRef,
	useState
} from 'react'
import {
	Dialog,
	Transition
} from '@headlessui/react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard'
import XIcon from 'assets/icons/x.svg'

type ReferralPopupProps = {
	link: string
}

export const ReferralPopup: FC<ReferralPopupProps> = ({ link }) => {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(true)
	const completeButtonRef = useRef(null)
	const handleClose = useCallback(
		() => setIsOpen(false),
		[setIsOpen]
	)

	return (
		<Transition
			show={isOpen}
			as={Fragment}
		>
			<Dialog
				initialFocus={completeButtonRef}
				as={'div'}
				className={'fixed z-40 inset-0 overflow-y-auto z-modal px-2'}
				open={isOpen}
				onClose={handleClose}
			>
				<Dialog.Overlay
					className={'fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-40'}
				/>
				<Transition.Child
					as={Fragment}
					enter={'transition ease-out duration-150'}
					enterFrom={'transform scale-95'}
					enterTo={'transform scale-100'}
					leave={'transition ease-in duration-100'}
					leaveFrom={'transform scale-100'}
					leaveTo={'transform scale-95'}
				>
					<div
						className={classNames(
							'relative flex flex-col w-full mx-auto my-24 rounded-xl shadow-lg',
							'bg-white border border-gray-200',
							'max-w-lg p-5'
						)}
					>
						<button
							onClick={handleClose}
							className={classNames(
								'absolute text-sm cursor-base text-gray-600 hover:text-primary-500 top-4 right-4'
							)}
						>
							<XIcon
								className={'w-6 h-6'}
								label={'close'}
							/>
						</button>
						<header
							className={'text-xl font-semibold'}
						>
							{t('common:referralPopup.title')}
						</header>
						<p
							className={'mt-1 text-neutral-500'}
						>
							{t('common:referralPopup.description')}
						</p>
						<CopyToClipboard
							className={'mt-8'}
							value={link}
							label={t('common:referralPopup.label')}
						/>
					</div>
				</Transition.Child>
			</Dialog>
		</Transition>
	)
}
