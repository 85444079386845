import { FirebaseTimestamp } from '@shared/types/FirebaseTimestamp.type'
import { Timestamp } from 'firebase/firestore'
import momentUtc from '../momentUtc'

export const firebaseTimestampToMomentUtc = (firebaseTimestamp: FirebaseTimestamp): moment.Moment => {
	let timestamp: Timestamp
	if (
		'seconds' in firebaseTimestamp &&
		'nanoseconds' in firebaseTimestamp
	) {
		timestamp = new Timestamp(firebaseTimestamp.seconds, firebaseTimestamp.nanoseconds)
	} else {
		timestamp = new Timestamp(firebaseTimestamp._seconds, firebaseTimestamp._nanoseconds)
	}
	return momentUtc(timestamp.toDate())
}
