import axios, { AxiosResponse } from 'axios'
import {
	Delivery,
	Session
} from '@rechargeapps/storefront-client'

type GetCustomerDeliveryScheduleParams = {
	session: Session
	deliveryCountFuture?: number
	futureInterval?: number
	dateMax?: string
}

export const getCustomerDeliverySchedule = async (body: GetCustomerDeliveryScheduleParams) => {
	try {
		if (!body.session.customerId) {
			console.error('getCustomerDeliverySchedule', 'customerId is null')
			return null
		}
		const { data } = await axios.post<GetCustomerDeliveryScheduleParams, AxiosResponse<Delivery[]>>('/api/get-delivery-schedule', body)
		return data
	} catch (error) {
		console.error(error)
		return null
	}
}
