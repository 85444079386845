import { BusinessConfigDelivery } from '@shared/businessConfig'
import { RechargeCharge } from '@shared/interfaces/RechargeCharge.interface'
import { Moment } from 'moment'
import { MealSelection } from '.'
import { Order } from '@rechargeapps/storefront-client'

export const getMealSelectionByMoment = (
	deliveryMoment: Moment,
	deliveries: BusinessConfigDelivery[],
	currentMoment: Moment,
	paymentStatus: 'paid' | 'unpaid',
	chargeOrOrder?: RechargeCharge | Order
): MealSelection | null => {
	const delivery = deliveries.find(e => e.deliveryDay === deliveryMoment.day())
	if (!delivery) {
		return null
	}
	const minDaysBeforeMealSelection = delivery?.minDaysBeforeMealSelection || 0
	let maxMealSelectionMoment = deliveryMoment.clone().subtract(
		minDaysBeforeMealSelection,
		'days'
	).endOf('day')
	if (delivery.maxSelectionHour) {
		maxMealSelectionMoment = maxMealSelectionMoment.hour(delivery.maxSelectionHour)
	}

	return {
		deliveryMoment,
		isMealsSelectionTooLate: currentMoment.isAfter(maxMealSelectionMoment),
		maxMealSelectionMoment,
		paymentStatus,
		chargeOrOrder
	}
}
