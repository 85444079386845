import { Days } from '../../enums/days.enum'

export type DayNames =
	'Monday' |
	'Tuesday' |
	'Wednesday' |
	'Thursday' |
	'Friday' |
	'Saturday' |
	'Sunday'

export const getIsDayNames = (dayName: string): dayName is DayNames => {
	return [
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday'
	].includes(dayName)
}
export const convertDayNameToNumber = (
	dayName: string
): number | null => {
	if (!getIsDayNames(dayName)) return null
	return {
		Monday: Days.MONDAY,
		Tuesday: Days.TUESDAY,
		Wednesday: Days.WEDNESDAY,
		Thursday: Days.THURSDAY,
		Friday: Days.FRIDAY,
		Saturday: Days.SATURDAY,
		Sunday: Days.SUNDAY
	}[dayName]
}
