import { Moment } from 'moment'
import { getNearestDayMoment } from './getNearestDayMoment'
import {
	convertDayNameToNumber,
	DayNames
} from './converters/convertDayNameToNumber'
import { stringToDate } from './converters/stringToDate'
import { Days } from '../enums/days.enum'
import {
	getNearestAvailableDeliveryDate
} from './getNearestAvailableDeliveryDate'
import { BusinessConfigDelivery } from '../businessConfig'
import getCurrentMoment from './getCurrentMoment'
import momentUtc from './momentUtc'

export type GetDeliveryDateMomentByChargeDateMomentParams = {
	chargeDateMoment: Moment
	deliveryDayName?: DayNames
	firstDeliveryDate?: string | null
	oneTimeDeliveryDate?: string | null
	deliveries: BusinessConfigDelivery[]
	validationMoment: Moment
	validateFirstDeliveryDateMealSelection?: boolean
}

type GetDeliveryDateMomentByChargeDateMoment = (
	params: GetDeliveryDateMomentByChargeDateMomentParams
) => Moment

const getIsFirstDeliveryDateAvailable = (
	firstDeliveryDate: string,
	deliveries: BusinessConfigDelivery[],
	validationMoment: Moment,
	validateFirstDeliveryDateMealSelection: boolean
) => {
	const firstDeliveryDateMoment = momentUtc(stringToDate(firstDeliveryDate))
	// If the first delivery date is in the past, return false
	if (
		!firstDeliveryDateMoment.isValid() ||
		firstDeliveryDateMoment.isBefore(validationMoment)
	) return false
	if (validateFirstDeliveryDateMealSelection === false) return true
	const delivery = deliveries.find(
		e => e.deliveryDay === firstDeliveryDateMoment.day()
	)
	if (!delivery) return false
	const { minDaysBeforeMealSelection } = delivery
	const currentDateDeliveryDateDiffDays = firstDeliveryDateMoment.diff(
		validationMoment,
		'days'
	)
	if (!minDaysBeforeMealSelection) return false
	// If the first delivery date is after meal selection, return false
	return currentDateDeliveryDateDiffDays >= minDaysBeforeMealSelection
}

export const getDeliveryDateMomentByChargeDateMoment:
	GetDeliveryDateMomentByChargeDateMoment = ({
		chargeDateMoment,
		deliveryDayName,
		firstDeliveryDate,
		oneTimeDeliveryDate,
		deliveries,
		validationMoment,
		validateFirstDeliveryDateMealSelection = true
	}) => {
		let deliveryDay: Days | undefined
		if (oneTimeDeliveryDate) {
			const oneTimeDeliveryMoment = momentUtc(stringToDate(oneTimeDeliveryDate))
			if (oneTimeDeliveryMoment.isAfter(validationMoment)) return oneTimeDeliveryMoment
		}
		if (firstDeliveryDate) {
			const firstDeliveryDateMoment = momentUtc(stringToDate(firstDeliveryDate))
			const isFirstDeliveryDateAvailable = getIsFirstDeliveryDateAvailable(
				firstDeliveryDate,
				deliveries,
				validationMoment,
				validateFirstDeliveryDateMealSelection
			)
			if (isFirstDeliveryDateAvailable) return firstDeliveryDateMoment
		}
		if (deliveryDayName) {
			// Get delivery day by delivery day name
			const delivery = deliveries.find(
				e => e.deliveryDay === convertDayNameToNumber(deliveryDayName)
			)
			deliveryDay = delivery?.deliveryDay
		}
		const todayDateMomentWithoutTime = getCurrentMoment().startOf('day')
		if (deliveryDay) {
			return getNearestDayMoment(deliveryDay, chargeDateMoment)
		}
		return getNearestAvailableDeliveryDate(
			todayDateMomentWithoutTime,
			deliveries,
			chargeDateMoment
		)
	}
