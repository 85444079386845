import {
	FormLabel,
	FormLabelProps
} from '@chakra-ui/react'
import classNames from 'classnames'
import { FC } from 'react'

interface InputLabelProps extends FormLabelProps {
	type?: string;
	className?: string
}

export const InputLabel: FC<InputLabelProps> = ({
	children,
	type,
	className
}) => {
	return (
		<FormLabel
			className={classNames(
				'!text-sm text-neutral-900 !mb-1',
				type === 'checkbox' && 'hidden',
				className
			)}
		>
			{ children }
		</FormLabel>
	)
}