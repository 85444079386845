import {
	useCallback,
	useEffect,
	useState
} from 'react'
import { UseAsyncStatus } from '~/hooks/useAsyncWithParams'
import * as Sentry from '@sentry/nextjs'

interface UseAsyncProps {
	asyncFunction: () => Promise<any>;
	onError?: (error: Error) => void;
	onSuccess?: (value: any) => void;
	immediate?: boolean;
	disableSentryCapture?: boolean;
}

type UseAsyncHook = (props: UseAsyncProps) => {
	execute: () => void,
	status: UseAsyncStatus,
	value: any,
	error: any
}

export const useAsync: UseAsyncHook = ({
	asyncFunction,
	onError,
	onSuccess,
	immediate = false,
	disableSentryCapture = false
}) => {
	const [status, setStatus] = useState<UseAsyncStatus>('idle')
	const [value, setValue] = useState(null)
	const [error, setError] = useState(null)
	// The execute function wraps asyncFunction and
	// handles setting state for pending, value, and error.
	// useCallback ensures the below useEffect is not called
	// on every render, but only if asyncFunction changes.
	const execute = useCallback(() => {
		setStatus('pending')
		setValue(null)
		setError(null)
		return asyncFunction()
			.then((response) => {
				setValue(response)
				setStatus('success')
				onSuccess?.(response)
			})
			.catch((error) => {
				if (!disableSentryCapture) Sentry.captureException(error)
				setError(error)
				setStatus('error')
				onError?.(error)
			})
	}, [asyncFunction, disableSentryCapture, onError, onSuccess])

	// Call execute if we want to fire it right away.
	// Otherwise, execute can be called later, such as
	// in an onClick handler.
	useEffect(() => {
		if (immediate) execute()
	}, [execute, immediate])
	return {
		execute,
		status,
		value,
		error
	}
}
