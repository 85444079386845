import { httpsCallable } from 'firebase/functions'
import { selector } from 'recoil'
import { functions } from '~/adapters/firebase'
import { RechargeCharge } from '~/functions/src/shared/interfaces/RechargeCharge.interface'
import { userActiveSubscriptionsWithAddressesState } from './userActiveSubscriptionsWithAddressesState'

const getNextChargesBySubscriptionId = httpsCallable<{ subscriptionId: number; limit?: number }, RechargeCharge[]>(
	functions,
	'subscriptions-getNextChargesBySubscriptionId'
)

export const nextChargesBySubscriptionIdState = selector<{
	[subscriptionId: number]: RechargeCharge[]
}>({
	key: 'subscriptions:nextChargesBySubscriptionId',
	get: async ({ get }) => {
		const charges: {
			[subscriptionId: number]: RechargeCharge[]
		} = {
		}
		const subscriptions = get(userActiveSubscriptionsWithAddressesState)
		for (const e of subscriptions) {
			try {
				const { data } = await getNextChargesBySubscriptionId({
					subscriptionId: e.subscription.id,
					limit: 1
				})
				const firstCharge = data[0]
				if (!firstCharge) continue
				const subscriptionId = firstCharge.line_items[0].purchase_item_id
				charges[subscriptionId] = data
			} catch (error) {
				console.error(error)
			}
		}
		return charges
	}
})
