import { selector } from 'recoil'
import { userSubscriptionsWithAddressesState } from './userSubscriptionsWithAddressesState'

export const subscriptionsRegionsState = selector<string[]>({
	key: 'subscriptions:subscriptionsRegions',
	get: ({ get }) => {
		const userSubscriptionsWithAddresses = get(userSubscriptionsWithAddressesState) ?? []
		const regions = userSubscriptionsWithAddresses.map(e => e.address.country_code)
		return Array.from(new Set(regions)).filter(Boolean)
	}
})
