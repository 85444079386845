import { i18n } from '~/next-i18next.config.js'
import { atom } from 'recoil'
import businessConfig from '@shared/businessConfig'
import getCurrentMoment from '@shared/helpers/getCurrentMoment'
import { getNearestAvailableDeliveryDate } from '~/functions/src/shared/helpers/getNearestAvailableDeliveryDate'
import { getRegionFromLocale } from '~/helpers/locale'
import { persistAtom } from '~/store/effects/persistRecoil'

const deliveries = businessConfig.deliveriesByRegion[
	getRegionFromLocale(i18n.defaultLocale)
]

// Warning: Do not cache this value. It has to be updated every day.
export const selectedDeliveryDateState = atom({
	key: 'checkout:selectedDeliveryDate',
	default: (() => {
		const todayDateMomentWithoutTime = getCurrentMoment().startOf('day')
		const nearestDeliveryDate = getNearestAvailableDeliveryDate(
			todayDateMomentWithoutTime,
			deliveries
		)

		return nearestDeliveryDate.format('DD/MM/YYYY')
	})(),
	effects_UNSTABLE: [persistAtom('session')]
})
