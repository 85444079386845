import {
	useRecoilState,
	useRecoilValueLoadable
} from 'recoil'
import { featureFlagsState } from '~/store/atoms/featureFlags'
import {
	useEffect,
	useMemo,
	useState
} from 'react'
import { UseAsyncStatus } from '~/hooks/useAsyncWithParams'
import { analytics } from '~/helpers/scripts/analytics'
import posthog from 'posthog-js'
import businessConfig from '@shared/businessConfig'
import { getShopifyId } from '~/helpers/getShopifyId'
import { referralState } from '~/store/atoms/checkout/referralState'
import { useDiscountCode } from '~/hooks/useDiscountCode'
import { userRoleState } from '~/store/atoms/dashboard/userRoleState'
import { auth } from '~/adapters/firebase'
import { getServerSideFeatureFlags } from '~/adapters/posthog/getServerSideFeatureFlags'
import { useLocalStorage } from 'usehooks-ts'

export const useWithAuthentication = (props: Record<string, any>) => {
	const [, setFeatureFlags] = useRecoilState(featureFlagsState)
	const [user, setUser] = useState(props.user)
	const [customerData, setCustomerData] = useState(props.customerData)
	const [customerDataStatus, setCustomerDataStatus] = useState<UseAsyncStatus>(props.customerData ? 'success' : 'idle')
	const [customerAccessToken, setCustomerAccessToken] = useState(props.customerAccessToken)
	const [customerAccessTokenLoading, setCustomerAccessTokenLoading] = useState<boolean>(Boolean(props.customerAccessTokenLoading))
	const { featureFlags } = props

	useEffect(() => {
		if (!featureFlags || Object.keys(featureFlags).length === 0) return
		setFeatureFlags(featureFlags)
	}, [featureFlags, setFeatureFlags])

	useEffect(() => {
		analytics(window, document, 'script', 'dataLayer', 'GTM-PFS3TG7')
		posthog.init(businessConfig.posthogToken, {
			api_host: 'https://app.posthog.com',
			autocapture: false,
			enable_recording_console_log: false,
			opt_out_capturing_by_default: process.env.NODE_ENV === 'development',
			loaded: (posthog) => {
				if (!customerData?.id) return
				posthog.identify(getShopifyId(customerData.id), {
					email: user?.email
				})
			}
		})
	}, [customerData?.id, user?.email])

	const referral = useRecoilValueLoadable(referralState)
	const [appliedReferralCode] = useLocalStorage('appliedReferralCode', null)
	const { addDiscountCode } = useDiscountCode()
	const [userRole] = useRecoilState(userRoleState)

	useEffect(() => {
		if (
			!customerData
		) return
		if (window.gtag) {
			window.gtag('set', {
				user_id: getShopifyId(customerData.id)
			})
		}
		if (window.analytics) {
			window.analytics.identify(
				getShopifyId(customerData.id),
				{
					email: customerData.email,
					firstName: customerData.firstName,
					lastName: customerData.lastName
				}
			)
		}
		posthog.identify(getShopifyId(customerData.id), {
			email: user?.email
		})
		posthog.people.set({
			email: customerData.email
		})
	}, [customerData, user?.email])

	// force refresh the token every 10 minutes
	useEffect(() => {
		const handle = setInterval(async () => {
			const currentUser = auth.currentUser
			if (currentUser) await currentUser.getIdToken(true)
		}, 10 * 60 * 1000)

		// clean up setInterval
		return () => clearInterval(handle)
	}, [])

	useEffect(() => {
		if (
			referral.state !== 'hasValue' ||
			!appliedReferralCode
		) return
		addDiscountCode({
			discountCode: referral.contents.fields.referralDiscountCode,
			showNotification: false
		})
	}, [referral, appliedReferralCode, addDiscountCode])

	useEffect(() => {
		if (!customerData) return
		getServerSideFeatureFlags(getShopifyId(customerData.id)).then(flags => {
			if (!flags) return
			if (Object.keys(flags).length === 0) return
			setFeatureFlags(flags)
		})
	}, [customerData, setFeatureFlags])

	const userContextValue = useMemo(() => {
		return {
			user,
			setUser,
			featureFlags,
			customerData,
			setCustomerData,
			customerDataStatus,
			setCustomerDataStatus,
			customerAccessToken,
			setCustomerAccessToken,
			customerAccessTokenLoading,
			setCustomerAccessTokenLoading
		}
	}, [customerAccessToken, customerAccessTokenLoading, customerData, customerDataStatus, featureFlags, user])

	return {
		userContextValue,
		userRole,
		setUser,
		setCustomerData,
		setCustomerDataStatus,
		setCustomerAccessToken,
		setCustomerAccessTokenLoading
	}
}
