import { GetDiscountCodeByCodeArguments } from '~/interfaces/DiscountCode.interface'
import { GetDiscountCodeByCodeResponse } from '@shared/types/GetDiscountCodeByCodeResponse'
import axios from 'axios'

export const getDiscountCodeByCode = async (
	code: string
): Promise<GetDiscountCodeByCodeResponse> => {
	const response = await axios.post<
	GetDiscountCodeByCodeArguments,
	{ data: GetDiscountCodeByCodeResponse }
	>('/api/get-discount-code', {
		code
	})

	return response.data
}
