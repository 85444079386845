import businessConfig from '@shared/businessConfig'
import { RechargeSubscription } from '@shared/interfaces/RechargeSubscription.interface'
import { Moment } from 'moment'
import { getDeliveryDayNameFromDelivery } from '../extractors/getDeliveryDayNameFromDelivery'
import { getFirstDeliveryDateFromSubscription } from '../extractors/getFirstDeliveryDateFromSubscription'
import { getCorrectDeliveryDayName } from '../getCorrectDeliveryDayName'
import { getMealSelectionDeliveryDate } from '../getMealSelectionDeliveryDate'
import momentUtc from '../momentUtc'
import { DayNames } from '@shared/helpers/converters/convertDayNameToNumber'
import { Delivery } from '@rechargeapps/storefront-client'

const getDeliveriesBySubscriptionId = (
	deliverySchedule: Delivery[],
	subscriptionId: number
): Delivery[] => {
	if (!deliverySchedule) return []
	return deliverySchedule.filter(
		delivery => delivery.orders.find(
			order => order.line_items.find(lineItem => lineItem.subscription_id === subscriptionId)
		)
	)
}

export const getDeliveryMomentsByDeliverySchedule = (
	deliverySchedule: Delivery[],
	subscription: RechargeSubscription,
	currentDateMoment: Moment,
	region: string,
	deliveryDayName?: DayNames
): Moment[] => {
	return getDeliveriesBySubscriptionId(deliverySchedule, subscription.id).map(delivery => {
		let correctDeliveryDayName = deliveryDayName
		if (!correctDeliveryDayName) {
			const subscriptionDeliveryDayName = getDeliveryDayNameFromDelivery(delivery, subscription.id)
			correctDeliveryDayName = getCorrectDeliveryDayName(
				subscriptionDeliveryDayName,
				getFirstDeliveryDateFromSubscription(subscription)
			)
		}
		// Delivery schedule date is the same as charge date
		const chargeMoment = momentUtc(delivery.date, businessConfig.rechargeDateFormat)
		return getMealSelectionDeliveryDate({
			nextChargeDateMoment: chargeMoment,
			deliveryDayName: correctDeliveryDayName,
			currentDateMoment,
			region
		})
	})
}
