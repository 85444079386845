import gql from 'graphql-tag'

export const customerQuery = gql`
	query customer($customerAccessToken: String!, $language: LanguageCode!, $country: CountryCode!) @inContext(language: $language, country: $country) {
		customer (
			customerAccessToken: $customerAccessToken
		) {
				id
        acceptsMarketing
        createdAt
        displayName
        email
				firstName
				lastName
				phone
				defaultAddress {
						id
				}
				orders (first: 5, sortKey: PROCESSED_AT, reverse: true) {
						edges {
								node {
										id
										orderNumber
                    fulfillmentStatus
                    financialStatus
										discountApplications (first: 5) {
                      nodes {
                        __typename
                        ...on DiscountCodeApplication {
                          code
													targetType
													allocationMethod
													value {
                            __typename
                            ...on MoneyV2 {
                              amount
                            }
                            ...on PricingPercentageValue {
                              percentage
                            }
                          }
                        }
                      }
                    }
                    subtotalPriceV2 {
		                    amount
		                    currencyCode
                    }
                    totalShippingPriceV2 {
		                    amount
		                    currencyCode
                    }
                    totalPriceV2 {
                        amount
                        currencyCode
                    }
										totalTaxV2 {
												amount
												currencyCode
										}
                    shippingAddress {
		                    formatted(withName: true)
												countryCodeV2
                    }
										lineItems (first: 10) {
												edges {
														node {
															title
															quantity
															customAttributes {
																	key
																	value
															}
															variant {
																	id
																	title
																	sku
																	selectedOptions {
																			name
																			value
																	}
																	priceV2 {
																			amount
																			currencyCode
																	}
																	image {
																			width
																			height
																			url
																			altText
																	}
															}
														}
												}
										}
                    processedAt
								}
						}
				}
				addresses (first: 20) {
						edges {
								node {
										id
										address1
										address2
										city
										company
										country
										firstName
										lastName
										phone
										province
										zip
								}
						}
				}
		}
	}
`
