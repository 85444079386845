import Link from 'next/link'
import { Button } from '@chakra-ui/button'
import { useRouter } from 'next/router'
import { IButton } from '@shared/types/generated/contentful'
import { useCallback } from 'react'
import classNames from 'classnames'

interface NavbarDesktopMenuProps {
	buttons: IButton[]
	className?: string
}

const NavbarDesktopMenu = ({
	buttons,
	className
}: NavbarDesktopMenuProps): JSX.Element => {
	const router = useRouter()
	const getLinkHref = useCallback((button: IButton) => ({
		pathname: button.fields.href,
		query: router.query,
		hash: button.fields.hash
	}), [router.query])

	return (
		<div
			className={classNames(
				'hidden xl:flex items-center',
				className
			)}
		>
			{buttons.map(button => (
				<Link
					key={`desktop-navbar-link-${button.fields.href}`}
					passHref={true}
					href={getLinkHref(button)}
				>
					<Button
						variant={'ghost'}
						colorScheme={'secondary'}
						className={'first:ml-0 ml-4'}
						isDisabled={router.pathname === button.fields.href}
						style={{
							fontSize: '0.9rem'
						}}
					>
						{ button.fields.text }
					</Button>
				</Link>
			))}
		</div>
	)
}

export default NavbarDesktopMenu
