import { RechargeCharge } from '@shared/interfaces/RechargeCharge.interface'
import businessConfig from '@shared/businessConfig'

export const getFirstDeliveryDateFromCharge = (
	charge: RechargeCharge
): string | undefined => {
	return charge.line_items[0].properties.find(
		property => property.name === businessConfig.firstDeliveryDateAttributeKey
	)?.value
}
