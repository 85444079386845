import {
	FC,
	ReactElement,
	useCallback
} from 'react'
import {
	MutableSnapshot,
	RecoilRoot
} from 'recoil'
import ComponentProvider from '~/components/ComponentProvider'
import GlobalProvider from '~/providers/GlobalProvider'
import { AuthenticationProvider } from './AuthenticationProvider'
import { baseMealPlanProductQuery } from '~/store/atoms/checkout/baseMealPlanProductQuery'
import { selectedDeliveryDateState } from '~/store/atoms/checkout/selectedDeliveryDateState'
import { selectedLocaleState } from '~/store/atoms/checkout/selectedLocaleState'
import { loyaltyActionsState } from '~/store/atoms/customer/loyaltyActionsState'
import {
	dashboardSettingsState,
	globalSettingsState
} from '~/store/global'
import { useRouter } from 'next/router'
import { getDefaultSelectedDeliveryDate } from '~/helpers/getDefaultSelectedDeliveryDate'
import { getRegionFromLocale } from '~/helpers/locale'
import { UserContextProvider } from './UserContextProvider'
import { GlobalStaticProps } from '~/interfaces/GlobalStaticProps.interface'
import { IGlobalSettingsFields } from '~/functions/src/shared/types/generated/contentful'

interface StaticDataProviderProps {
	props: GlobalStaticProps & {
		[key: string]: any
	};
  children: ReactElement | ReactElement[]
}

const StaticDataProvider: FC<StaticDataProviderProps> = ({
	children,
	props
}) => {
	const router = useRouter()
	const initializeState = useCallback(({ set }: MutableSnapshot) => {
		const region = getRegionFromLocale(router.locale)
		const globalSettings: IGlobalSettingsFields = props.globalSettings?.fields ?? {
			title: 'Global Settings',
			loyaltySpendLimit: 0,
			tagsByZipcode: []
		}
		const dashboardSettings = props.dashboardSettings ?? {
			title: 'Dashboard Settings',
			disabledDeliveryDatesByRegion: {
			},
			checkoutCalendarNotice: {
			}
		}
		const selectedDeliveryDate = getDefaultSelectedDeliveryDate(
			dashboardSettings,
			globalSettings,
			region
		)
		set(baseMealPlanProductQuery, props.baseMealPlanProductQuery)
		set(dashboardSettingsState, dashboardSettings)
		set(globalSettingsState, globalSettings)
		set(selectedLocaleState, router.locale || null)
		if (props.loyaltyActions && props.loyaltyActions.length > 0) {
			set(loyaltyActionsState, props.loyaltyActions)
		}
		set(selectedDeliveryDateState, selectedDeliveryDate)
	}, [router.locale, props])

	return (
		<RecoilRoot
			initializeState={initializeState}
		>
			<UserContextProvider
				props={props}
			>
				<AuthenticationProvider
					props={props}
				>
					<GlobalProvider>
						<ComponentProvider
							props={props}
						>
							{children}
						</ComponentProvider>
					</GlobalProvider>
				</AuthenticationProvider>
			</UserContextProvider>
		</RecoilRoot>
	)
}

export default StaticDataProvider
