import { useCallback } from 'react'
import { setCookie } from 'cookies-next'

const usePersistLocaleCookie = () => {
	const updateLocaleCookie = useCallback((newLocale: string) => {
		setCookie('NEXT_LOCALE', newLocale, {
			maxAge: 100 * 24 * 60 * 60 * 1000
		})
	}, [])

	return {
		updateLocaleCookie
	}
}

export default usePersistLocaleCookie
