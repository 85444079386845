import { httpsCallable } from 'firebase/functions'
import { LoyaltyChange } from '~/functions/src/shared/interfaces/LoyaltyChange.interface'
import { functions } from '../firebase'

export const redeemCouponCode = async (
	loyaltyActionId: string
): Promise<LoyaltyChange> => {
	const redeemCouponCodeFunction = httpsCallable(functions, 'loyalty-redeemCouponCode')
	const { data } = await redeemCouponCodeFunction({
		loyaltyActionId
	})

	return data as LoyaltyChange
}
