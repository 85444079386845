import React, {
	FC,
	ReactNode,
	useEffect
} from 'react'
import {
	Dialog as DialogBase,
	Transition
} from '@headlessui/react'
import XIcon from 'assets/icons/x.svg'
import classNames from 'classnames'
import { Button } from '@chakra-ui/button'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

interface DialogProps {
	children: ReactNode;
	open: boolean;
	color?: 'danger';
	heading?: string;
	padding?: boolean;
	xButtonColor?: string;
	promptButtons?: {
		loading: boolean;
		showCancelButton: boolean;
		actionButtonText: string;
		onActionButtonClick: () => void;
	};
	onClose: () => void;
	onTransitionEnd?: () => void;
	fullWidth?: boolean;
}

const Dialog: FC<DialogProps> = ({
	children,
	open,
	color,
	xButtonColor = '#333',
	padding = true,
	onClose,
	onTransitionEnd,
	heading,
	promptButtons,
	fullWidth = true
}) => {
	const { t } = useTranslation()
	const router = useRouter()
	if (
		router.events
	) {
		router.events.on('routeChangeComplete', () => {
			document.body.style.overflow = 'auto'
		})
	}

	useEffect(() => {
		document.body.style.overflow = open ? 'hidden' : 'auto'
	}, [open])
	return (
		<Transition
			show={open}
			onTransitionEnd={onTransitionEnd}
		>
			<DialogBase
				className={'fixed inset-0 z-40'}
				onClose={onClose}
			>
				<div
					className={'flex items-center justify-center h-full'}
				>
					<Transition.Child
						as={'div'}
						className={'fixed inset-0 bg-black bg-opacity-30 z-20 backdrop-blur-sm'}
						onClick={onClose}
						enter={'transition duration-150'}
						leave={'transition duration-150'}
						enterFrom={'opacity-0'}
						enterTo={'opacity-100'}
						leaveFrom={'opacity-100'}
						leaveTo={'opacity-0'}
					/>
					<Transition.Child
						as={'div'}
						className={classNames(
							'z-40 relative h-full sm:h-fit',
							'rounded-none sm:rounded-4xl',
							'max-w-xl overflow-y-scroll',
							padding && 'pt-12 px-6 pb-16 sm:p-8',
							color === 'danger' ? 'bg-red-50':'bg-white',
							fullWidth && 'w-full'
						)}
						enter={'transition ease-in-out duration-150'}
						enterFrom={'transform opacity-0 scale-95'}
						enterTo={'transform opacity-100 scale-100'}
						leave={'transition ease-out duration-100'}
						leaveFrom={'transform opacity-100 scale-100'}
						leaveTo={'transform opacity-0 scale-95'}
					>
						<button
							onClick={onClose}
							name={'close-dialog-button'}
							className={classNames(
								'p-2 hover:opacity-75 duration-100 rounded-md',
								'active:ring-2 active:opacity-50',
								'absolute right-4 top-4 ring-keppel-500',
								'hover:bg-opacity-25',
								color === 'danger' ? 'hover:bg-red-100':'hover:bg-neutral-100'
							)}
							aria-label={'Close popup'}
						>
							<XIcon
								width={22}
								height={22}
								stroke={xButtonColor}
							/>
						</button>
						{heading && (
							<div
								className={'w-full mb-1'}
							>
								<h3
									className={'text-xl font-semibold'}
								>
									{ heading }
								</h3>
							</div>
						)}
						{ children }
						{ promptButtons && (
							<div
								className={classNames(
									'w-full flex flex-col-reverse items-start justify-end border-t border-gray-100 pt-6',
									'sm:flex-row sm:items-center mt-6'
								)}
							>
								{promptButtons.showCancelButton && (
									<Button
										variant={'link'}
										className={'mt-4 sm:mt-0 sm:mr-5 w-full sm:w-auto'}
										onClick={onClose}
										type={'button'}
									>
										{ t('common:cancelButtonText') }
									</Button>
								)}
								<Button
									className={'w-full sm:w-auto'}
									colorScheme={color || 'keppel'}
									variant={'solid'}
									onClick={promptButtons.onActionButtonClick}
									type={'submit'}
									isLoading={promptButtons.loading}
								>
									{ promptButtons.actionButtonText }
								</Button>
							</div>
						)}
					</Transition.Child>
				</div>
			</DialogBase>
		</Transition>
	)
}

export default Dialog
