import { extendTheme as extendChakraTheme } from '@chakra-ui/theme-utils'
import colors from 'tailwindcss/colors'

export const chakraTheme = extendChakraTheme({
	colors: {
		neutral: colors.neutral,
		gray: colors.neutral,
		palette: {
			champagne: '#F2E3CC',
			linen: '#F8F0E4',
			frenchRose: '#FC5689',
			mauve: '#D4B8FF',
			orangePeel: '#FF9E30',
			darkGreen: '#0A3632',
			nyanza: '#E1FFD6',
			emerald: '#25C88E'
		},
		primary: {
			'100': '#FFF5E5',
			'200': '#FFEDD5',
			'300': '#FFD8A8',
			'400': '#FFB25E',
			'500': '#FF9E30',
			'600': '#FF8C00',
			'700': '#FF7A00',
			'800': '#FF6800',
			'900': '#FF5600'
		},
		secondary: {
			'50': '#DFFBF5',
			'100': '#CFF9F0',
			'200': '#B0F4E7',
			'300': '#90F0DE',
			'400': '#71EBD5',
			'500': '#25C88E',
			'600': '#1E9F70',
			'700': '#177751',
			'800': '#105032',
			'900': '#091713'
		},
		secondaryDark: {
			'500': '#0A3632'
		},
		'keppel': {
			DEFAULT: '#2EA6A6',
			'50': '#A5E6E6',
			'100': '#95E2E2',
			'200': '#76D9D9',
			'300': '#56D0D0',
			'400': '#37C6C6',
			'500': '#2EA6A6',
			'600': '#227A7A',
			'700': '#164E4E',
			'800': '#092222',
			'900': '#000000'
		},
		'danger': colors.red,
		'warning': colors.orange,
		'success': colors.emerald
	},
	shadows: {
		outline: '0 0 0 3px secondary'
	},
	components: {
		Input: {
			defaultProps: {
				focusBorderColor: '#25C88E'
			},
			sizes: {
				sm: {
					field: {
						borderRadius: '2rem'
					}
				},
				md: {
					field: {
						borderRadius: '2rem'
					}
				},
				lg: {
					field: {
						borderRadius: '2rem'
					}
				},
				xl: {
					field: {
						borderRadius: '2rem'
					}
				}
			}
		},
		Heading: {
			baseStyle: {
				fontWeight: 'normal'
			}
		},
		Select: {
			defaultProps: {
				focusBorderColor: '#25C88E'
			},
			sizes: {
				sm: {
					field: {
						borderRadius: '2rem'
					}
				},
				md: {
					field: {
						borderRadius: '2rem',
						padding: '0.5rem 1rem'
					}
				},
				lg: {
					field: {
						borderRadius: '2rem'
					}
				},
				xl: {
					field: {
						borderRadius: '2rem'
					}
				}
			}
		},
		NumberInput: {
			defaultProps: {
				focusBorderColor: '#25C88E'
			},
			sizes: {
				sm: {
					field: {
						borderRadius: '2rem'
					}
				},
				md: {
					field: {
						borderRadius: '2rem'
					}
				},
				lg: {
					field: {
						borderRadius: '2rem'
					}
				},
				xl: {
					field: {
						borderRadius: '2rem'
					}
				}
			}
		},
		Checkbox: {
			defaultProps: {
				focusBorderColor: '#25C88E'
			}
		},
		Button: {
			baseStyle: {
				borderRadius: '2rem'
			}
		}
	},
	fonts: {
		heading: '"Hammer", serif',
		body: '\'Outfit\', sans-serif'
	}
})
