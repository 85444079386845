import { CustomerUpdateMutation } from '~/mutations/CustomerUpdate.mutation'
import { customerUpdateVariables } from '@shared/types/shopify'
import apolloStorefrontClient from '@shared/adapters/apolloStorefrontClient'

export const customerUpdate = async (variables: customerUpdateVariables) => {
	return apolloStorefrontClient.mutate({
		mutation: CustomerUpdateMutation,
		variables
	})
}
