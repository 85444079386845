import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import {
	featureFlagsLoadedState,
	featureFlagsState
} from '~/store/atoms/featureFlags'
import config from '~/config'

type FeatureFlagKeys = keyof typeof config.defaultFeatureFlags

/*
 * This hook is used to get the feature flags from PostHog
 * Docs: https://posthog.com/docs/features/feature-flags
 */
export const useFeatureFlags = () => {
	const featureFlagsLoaded = useRecoilValue(featureFlagsLoadedState)
	const featureFlags = useRecoilValue(featureFlagsState)
	// Safely retrieve feature flags from PostHog, fallback to default values
	const getFeatureFlag = useCallback((flagName: FeatureFlagKeys) => {
		if (process.env.NODE_ENV === 'development') return true
		const defaultFlagValue = config.defaultFeatureFlags?.[flagName]
		return featureFlags?.[flagName] ?? defaultFlagValue ?? false
	}, [
		featureFlags
	])

	return {
		getFeatureFlag,
		featureFlagsLoaded
	}
}
