import { useRouter } from 'next/router'
import {
	useCallback,
	useMemo
} from 'react'
import {
	useRecoilState,
	useRecoilValue,
	useRecoilValueLoadable
} from 'recoil'
import getDeliveryInfo from '~/functions/src/shared/helpers/getDeliveryInfo'
import { getRegionFromLocale } from '~/helpers/locale'
import { deliverySkipsBySubscriptionIdState } from '~/store/atoms/customer/deliverySkipsBySubscriptionIdState'
import { nextChargesBySubscriptionIdState } from '~/store/atoms/recharge/nextChargesBySubscriptionIdState'
import { subscriptionsRegionsState } from '~/store/atoms/recharge/subscriptionsRegionsState'
import { userRechargeOrdersState } from '~/store/atoms/recharge/userRechargeOrdersState'
import { userSubscriptionsWithAddressesByRegionState } from '~/store/atoms/recharge/userSubscriptionsWithAddressesByRegionState'
import { useCustomerDeliverySchedule } from './customer/useCustomerDeliverySchedule'
import { useTimezone } from './useTimezone'
import { RechargeSubscription } from '@shared/interfaces/RechargeSubscription.interface'
import { userSubscriptionsWithAddressesState } from '~/store/atoms/recharge/userSubscriptionsWithAddressesState'
import { RechargeAddress } from '@shared/interfaces/RechargeAddress.interface'
import moment from 'moment-timezone'

export const useSubscriptions = () => {
	const { locale } = useRouter()
	const regions = useRecoilValue(subscriptionsRegionsState)
	const customerRechargeOrders = useRecoilValue(userRechargeOrdersState)
	const nextChargesBySubscriptionId = useRecoilValueLoadable(nextChargesBySubscriptionIdState)
	const deliverySkipsBySubscriptionId = useRecoilValue(deliverySkipsBySubscriptionIdState)
	const { customerDeliverySchedule } = useCustomerDeliverySchedule()
	const region = useMemo(
		() => getRegionFromLocale(locale),
		[locale]
	)
	const [, setSubscriptionsWithAddresses] = useRecoilState(userSubscriptionsWithAddressesState)
	// All subscriptions
	const subscriptionsWithAddressesByRegion = useRecoilValue(
		userSubscriptionsWithAddressesByRegionState
	)
	const { getTimezoneCurrentMoment } = useTimezone()
	const numberOfSubscriptionsByRegion = useMemo(() => {
		return Object.fromEntries(regions.map(e => [
			e, subscriptionsWithAddressesByRegion[e.toLowerCase()]?.length ?? 0
		]))
	}, [regions, subscriptionsWithAddressesByRegion])
	const subscriptionsWithAddresses = useMemo(() => {
		return subscriptionsWithAddressesByRegion[region] ?? []
	}, [
		subscriptionsWithAddressesByRegion,
		region
	])
	const cancelledSubscriptionsWithAddresses = useMemo(() => {
		return subscriptionsWithAddresses.filter(e => e.subscription.status === 'cancelled')
	}, [subscriptionsWithAddresses])
	const subscriptions = useMemo(() => {
		return subscriptionsWithAddresses.map(subscriptionWithAddress => subscriptionWithAddress.subscription)
	}, [subscriptionsWithAddresses])
	const subscriptionsWithData = useMemo(() => {
		if (nextChargesBySubscriptionId.state === 'loading') return 'loading'
		return subscriptionsWithAddresses.map(subscriptionWithAddress => {
			const nextCharges = nextChargesBySubscriptionId.contents[subscriptionWithAddress.subscription.id] ?? []
			const deliverySkips = deliverySkipsBySubscriptionId[subscriptionWithAddress.subscription.id] ?? []
			const deliveryInfo = getDeliveryInfo({
				currentDateMoment: getTimezoneCurrentMoment(),
				region,
				subscription: subscriptionWithAddress.subscription,
				orders: customerRechargeOrders,
				deliverySchedule: customerDeliverySchedule,
				nextCharges,
				deliverySkips
			})

			return {
				subscriptionWithAddress,
				deliveryInfo,
				nextCharges,
				subscription: subscriptionWithAddress.subscription,
				address: subscriptionWithAddress.address,
				deliverySkips
			}
		})
	}, [customerDeliverySchedule, customerRechargeOrders, deliverySkipsBySubscriptionId, getTimezoneCurrentMoment, nextChargesBySubscriptionId.contents, nextChargesBySubscriptionId.state, region, subscriptionsWithAddresses])
	const activeSubscriptionsOtherRegions = useMemo(() => {
		const otherRegions: string[] = []

		regions.forEach(filteredRegion => {
			const filteredSubscriptionsWithAddresses = subscriptionsWithAddressesByRegion[filteredRegion.toLowerCase()] ?? []
			const numberOfSubscriptions = filteredSubscriptionsWithAddresses.filter(e => e.subscription.status === 'active').length
			if (numberOfSubscriptions > 0) {
				if (filteredRegion === region.toUpperCase()) return
				otherRegions.push(filteredRegion)
			}
		})
		return otherRegions
	}, [region, regions, subscriptionsWithAddressesByRegion])
	const activeDeliveriesSubscriptionsWithData = useMemo(() => {
		if (subscriptionsWithData === 'loading') {
			return 'loading'
		}
		return subscriptionsWithData.filter(e => {
			if (e.subscription.status === 'active') return true
			return e.deliveryInfo.upcomingDeliveryMoment
		})
	}, [subscriptionsWithData])
	const updateSubscription = useCallback((
		subscriptionId: number,
		subscription?: RechargeSubscription,
		address?: RechargeAddress
	) => {
		setSubscriptionsWithAddresses(prevValue => {
			const subscriptionIndex = prevValue.findIndex(e => e.subscription.id === subscriptionId)
			if (subscriptionIndex === -1) return prevValue
			const newSubscriptionsWithAddresses = [...prevValue]
			const data = {
				...newSubscriptionsWithAddresses[subscriptionIndex]
			}
			if (subscription) data.subscription = subscription
			if (address) data.address = address
			newSubscriptionsWithAddresses[subscriptionIndex] = data
			return newSubscriptionsWithAddresses
		})
	}, [setSubscriptionsWithAddresses])
	const updateAddress = useCallback((addressId: number, data: RechargeAddress) => {
		setSubscriptionsWithAddresses(prevValue => {
			const subscriptionIndex = prevValue.findIndex(e => e.address.id === addressId)
			if (subscriptionIndex === -1) return prevValue
			const newSubscriptionsWithAddresses = [...prevValue]

			newSubscriptionsWithAddresses[subscriptionIndex] = {
				...newSubscriptionsWithAddresses[subscriptionIndex],
				address: data
			}
			return newSubscriptionsWithAddresses
		})
	}, [setSubscriptionsWithAddresses])
	const removeSubscription = useCallback((subscriptionId: number) => {
		setSubscriptionsWithAddresses(prevValue => {
			return prevValue.filter(e => e.subscription.id !== subscriptionId)
		})
	}, [setSubscriptionsWithAddresses])
	const recentlyCancelledSubscription = useMemo(() => {
		return cancelledSubscriptionsWithAddresses.sort((a, b) => {
			return moment(b.subscription.cancelled_at).diff(moment(a.subscription.cancelled_at))
		})[0] ?? null
	}, [cancelledSubscriptionsWithAddresses])

	return {
		// All subscriptions
		subscriptionsWithAddressesByRegion,
		subscriptionsWithAddresses,
		cancelledSubscriptionsWithAddresses,
		subscriptions,
		numberOfSubscriptionsByRegion,
		subscriptionsWithData,
		activeSubscriptionsOtherRegions,
		activeDeliveriesSubscriptionsWithData,
		recentlyCancelledSubscription,
		updateSubscription,
		updateAddress,
		removeSubscription
	}
}
