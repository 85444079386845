import { createClient } from 'contentful'

const contentfulClient = createClient({
	space: '3pov8nvld7rb',
	accessToken: 'X5KgZcqcjs3wd9qoHTvaJW8x0xIkYay_wuBQZwcpwog',
	environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT ??
		process.env.CONTENTFUL_ENVIRONMENT ??
		'master'
})

export default contentfulClient
